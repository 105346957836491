import React from 'react';
import {
    Button,
    TextField,
    CircularProgress,
    Paper,
    Tooltip,
    Slider,
    Grid,
    Dialog,
    DialogContent,
    DialogActions,
    Slide,
} from '@material-ui/core/';

import Skeleton from '@material-ui/lab/Skeleton';
import InfoIcon from '@material-ui/icons/Info';

import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import { Logger } from '@apricityhealth/web-common-lib/utils/Logger';
import { toBoolean } from '@apricityhealth/web-common-lib/utils/Utils';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import Config from '@apricityhealth/web-common-lib/Config';

import UploadPatientImage from '@apricityhealth/web-common-lib/components/UploadPatientImage';
import PatientImage from '@apricityhealth/web-common-lib/components/PatientImage';
import SelectPatientData from '@apricityhealth/web-common-lib/components/SelectPatientData';
import defaultImage from '@apricityhealth/web-common-lib/uploadImage.jpg';
import BodyImageMap from '@apricityhealth/web-common-lib/components/BodyImageMap';

import Moment from 'moment';

import T from 'i18n-react';
import _ from 'lodash';


const log = new Logger();

const PROGRESS_SIZE = 30;
const BLUETOOTH_CONTROL_TYPES = [
    'systolicBloodPressure',
    'diastolicBloodPressure',
    'temperature',
    'oxygen',
    'heartRate'
];

function c2f(celsisus) {
    return (celsisus * 9 / 5) + 32;
}

function f2c(fahrenheit) {
    return (fahrenheit - 32) * 5 / 9;
}

// convert any numbers ending with F, into Celsisus
function s2c(str) {
    let match = str.match(/([+-]?([0-9]*[.])?[0-9]+)F/g);
    let replaced = str.valueOf();
    for (let i = 0; i < match.length; ++i) {
        replaced = replaced.replace(match[i], f2c(parseFloat(match[i])).toFixed(1) + 'C');
    }
    log.debug("s2c:", str, replaced);
    return replaced;
}

function spliceString(str, index, count, add) {
    // We cannot pass negative indexes directly to the 2nd slicing operation.
    if (index < 0) {
        index = str.length + index;
        if (index < 0) {
            index = 0;
        }
    }

    return str.slice(0, index) + (add || "") + str.slice(index + count);
}

function isNull(v) {
    return v === '' || v === null || v === undefined;
}

export class SelectQuestion extends React.Component {
    constructor(props) {
        if (!props.question) {
            throw new Error("SelectQuestion requires the question props!")
        }
        super(props);

        this.imageUploadRef = React.createRef();
        let question = props.question;
        let checked = [];
        for (let i = 0; i < question.answers.length; ++i) {
            checked[question.answers[i].answerId] = false;
        }
        // if (props.embedded === true) {
        //     // HACK: remove any answers with a score of 0 when the question is embedded
        //     question.answers = question.answers.filter((a) => Number(a.score) !== 0);
        // }
        let textLabels = {};
        if (Array.isArray(props.symptomTracking)) {
            for (let i = 0; i < props.symptomTracking.length; ++i) {
                let track = props.symptomTracking[i];
                for (let k = 0; k < question.answers.length; ++k) {
                    let answer = question.answers[k];
                    if (question.dataId === track.dataId || answer.dataId === track.dataId) {
                        let trackingId = `tracking.${track.dataId}`;
                        let value = localStorage.getItem(trackingId);
                        if (value === null || value === undefined) continue;

                        if (track.type === 'count') {
                            checked[answer.answerId] = true;
                            textLabels[answer.answerId] = answer.text[0].valueOf();
                            answer.text[0] = `${value}`;
                            localStorage.setItem(trackingId, '0');
                        }
                        else if (track.type === 'boolean' && Number(value) > 0) {
                            checked[answer.answerId] = true;
                            localStorage.setItem(trackingId, '0');
                        }
                    }
                }
            }
        }

        const hasCategories = question.answers.some(i => i.category && i.category !== '');

        this.state = {
            actions: [],
            expandedCategories: hasCategories ? [] : undefined,
            response: props.response || '',
            temperatureUnit: props.temperatureUnit || 'F',
            question,
            checked,
            textLabels,
            answered: false,
            category: ''
        };

        // convert any text in F into C
        if (this.state.temperatureUnit === 'C') {
            let answers = question.answers;
            for (let i = 0; i < answers.length; ++i) {
                let answer = answers[i];
                if (answer.controlType === 'temperature') {
                    answer.text[0] = s2c(answer.text[0]);
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.reset !== this.props.reset && this.props.reset === true && this.state.answered === true) {
            this.setState({ answered: false });
        }
    }

    selectAnswer(answerId, e) {
        log.debug("selectAnswer:", answerId, e);
        const { parent } = this.props;
        let { answered, checked, question } = this.state;
        if (!answered) {
            checked[answerId] = e;

            // if not multi-select, then turn off the other check boxes..
            if (!question.multiSelect) {
                for (let i = 0; i < question.answers.length; ++i) {
                    const answer = question.answers[i];
                    if (answer.answerId === answerId)
                        continue;
                    checked[answer.answerId] = false;
                }
            }
            else {
                // handle singleton controlType
                for (let i = 0; i < question.answers.length; ++i) {
                    const answer = question.answers[i];
                    if (answer.controlType === 'singleton') {
                        if (answer.answerId === answerId) {
                            // turn off all the other answers..
                            for (let k in checked) {
                                if (k !== answerId) {
                                    checked[k] = false;
                                }
                            }
                        }
                        else {
                            checked[answer.answerId] = false;       // turn off the singleton
                        }
                    }
                }
            }

            const uploadImageAnswer = question.answers.find((answer) => answer.controlType === "uploadImage");
            if (uploadImageAnswer && !checked[uploadImageAnswer.answerId]) {
                const blobIds = uploadImageAnswer.text.filter(x => x).map(blobId => blobId);

                // trick for multi to reduce jump/flash; would like to unhave this soon
                const options = uploadImageAnswer.controlOptions || {};
                if (options.enableMultipleImages === 'true') {
                    uploadImageAnswer.text = [''];
                }

                this.imageUploadRef.current.deleteImages(blobIds).then((images) => {
                    uploadImageAnswer.text = [''];
                    this.forceUpdate();
                })
            }

            this.setState({ checked }, () => {
                if (parent) {
                    parent.setState({ refresh: true });     // force a state change in the parent so it will refresh
                }
            });
        }
    }

    getAnswers() {
        const { temperatureUnit } = this.props;
        const { question, checked } = this.state;

        let answers = [];
        for (var i = 0; i < question.answers.length; ++i) {
            const answer = question.answers[i];
            if (checked[answer.answerId]) {
                const isTemperature = answer.controlType === 'temperature';

                let text = answer.text[0];
                if (isTemperature && temperatureUnit === 'C') {
                    // convert back to F before submitting to the back-end
                    text = c2f(Number(text)).toFixed(1);
                    log.debug(`Converted ${answer.text[0]}C to ${text}F`);
                }

                if (answer.controlType === "uploadImage") {
                    text = answer.text.filter((x) => x).join(",");
                }

                answers.push({ answerId: answer.answerId, text });
            }
        }
        return answers;
    }

    validateAnswers() {
        let validated = true
        const { question, checked } = this.state;
        for (var i = 0; i < question.answers.length; ++i) {
            const answer = question.answers[i];
            if (!checked[answer.answerId]) continue;          // skip unanswered ones
            if (answer.errorText)
                validated = false;

        }
        return validated
    }

    canSkip() {
        const { question, checked } = this.state;
        if (this.state.answered) return false;
        if (question.answers.length === 0) return false;
        if (question.answers.some(i => i.invalid)) return false;
        // if any answer is checked, then don't display skip
        let answers = this.getAnswers();
        for(let i=0;i<answers.length;++i) {
            if (checked[answers[i].answerId] ) {
                return false;
            }
        }
        return !question.required;
    }
    
    canSubmit() {
        const { question } = this.state;
        if (this.state.answered) return false;
        if (question.answers.length === 0) return true;
        if (question.answers.some(i => i.invalid)) return false;
        if (!question.required) return true;
        let answers = this.getAnswers();
        return answers.length > 0;
    }

    getJournal() {
        const { question } = this.state;

        let journal = [];
        let answers = this.getAnswers();
        journal.push({ planId: question.planId, questionId: question.questionId, answers });

        return journal;
    }

    submitAnswers() {
        log.debug("submitAnswers", this);
        if (this.validateAnswers()) {
            let journal = this.getJournal();
            log.debug("journal:", journal);

            this.setState({ answered: true }, () => {
                if (this.props.onSubmit) {
                    this.props.onSubmit(journal);
                }
            });
        }
    }

    onChangeAnswerNumberFormat(answerIndex, value) {
        const { answered, question } = this.state;
        if (!answered) {
            const answer = question.answers[answerIndex];
            answer.text[0] = value;
            this.selectAnswer(answer.answerId, answer.text[0] ? true : false);
            this.setState({ question });
        }
    }

    onChangeBodyMapAnswerText(answerIndex, value) {
        console.debug(`onChangeBodyMapAnswerText `, answerIndex, value)
        const { answered, question } = this.state;
        if (!answered) {
            const answer = question.answers[answerIndex];
            answer.text[0] = String(value)
            this.selectAnswer(answer.answerId, answer.text[0] ? true : false);        // automatically select an answer when edited
            this.setState({ question });
        }
    }
    onChangeSliderAnswerText(answerIndex, e, value) {
        const { answered, question } = this.state;
        if (!answered) {
            const answer = question.answers[answerIndex];
            answer.text[0] = String(value)
            this.selectAnswer(answer.answerId, answer.text[0] ? true : false);        // automatically select an answer when edited
            this.setState({ question });
        }
    }

    onChangeAnswerText(answerIndex, e) {
        const { answered, question, temperatureUnit } = this.state;
        if (!answered) {
            const answer = question.answers[answerIndex];
            answer.invalid = false; //reset
            answer.errorText = null;//reset
            const isTemperature = answer.controlType === 'temperature';
            const options = answer.controlOptions || {};
            // set the value
            // NOTE: the text property on the UI answer model is an array; the text property on the submitted answer is a string
            if (answer.controlType === "uploadImage") {
                if (toBoolean(options.enableMultipleImages) && answer.text[0])
                    answer.text.push(e);
                else
                    answer.text[0] = e;
            } else if (answer.controlType === 'date') {
                answer.invalid = Moment(e.target.value).isValid() ? false : true;
                answer.text[0] = Moment(e.target.value).format("YYYY-MM-DD");
            } else {
                // TODO maybe able to simplify this condition; TBD
                answer.text[0] = e.target === undefined ? e : _.get(e, "target.value") || "";
            }

            if (answer.controlOptions) {
                // we need a decimal place for minScale
                if (options.minScale && answer.text[0].length >= Number(options.minScale)) {
                    let value = answer.text[0].replace(/\./g, '').replace(/\D/g, '');        // remove all . and non-numbers
                    if (value.length > Number(options.minScale)) {
                        value = spliceString(value, value.length - options.minScale, 0, '.');
                    }
                    answer.text[0] = value;
                }

                if (answer.text[0] && (!isNull(options.min) || !isNull(options.max))) {
                    let text = Number(answer.text[0]);
                    if (isTemperature && temperatureUnit === 'C')
                        text = Number(c2f(text).toFixed(1));

                    if ((!isNull(options.min) && text < Number(options.min)) || (!isNull(options.max) && text > Number(options.max))) {
                        log.debug(`anser out of range, min: ${options.min}, max: ${options.max}, text: ${text}`);
                        let min = !isNull(options.min) ? Number(options.min) : undefined;
                        let max = !isNull(options.max) ? Number(options.max) : undefined;
                        if (isTemperature && temperatureUnit === 'C') {
                            min = min !== undefined ? f2c(min).toFixed(1) : undefined;
                            max = max !== undefined ? f2c(max).toFixed(1) : undefined;
                        }
                        if (min !== undefined && max !== undefined)
                            answer.errorText = <T.span text={{ key: 'rangeError', min, max }} />;
                        else if (min !== undefined)
                            answer.errorText = <T.span text={{ key: 'rangeErrorMin', min }} />;
                        else if (max !== undefined)
                            answer.errorText = <T.span text={{ key: 'rangeErrorMax', max }} />;
                        answer.invalid = true;
                    }
                }

                if (options.noFutureDate) {
                    if (answer.text && answer.text[0]) {
                        const selectedDate = Moment(answer.text[0]);
                        if (selectedDate > Moment()) {
                            answer.errorText = <T.span text={{ key: 'dateFutureError' }} />
                            answer.invalid = true;
                        } else {
                            answer.errorText = null;
                            answer.invalid = answer.invalid || false;
                        }
                    }
                }

            }
            this.selectAnswer(answer.answerId, answer.text[0] ? true : false);        // automatically select an answer when edited
            this.setState({ question });
        }
    }

    submitBluetoothValue(type, value) {
        const { answered, question } = this.state;
        if (!answered && question) {
            let answerIndex = question.answers.findIndex((e) => e.controlType === type);
            if (answerIndex >= 0) {
                const answer = question.answers[answerIndex];
                answer.text[0] = Number(value).toFixed(1);
                this.selectAnswer(answer.answerId, answer.text[0] ? true : false);        // automatically select an answer when edited
                this.setState({ question });
            }
            else {
                log.error(`No answer found for type ${type}:`, question);
            }
        }
    }

    render() {
        const { appContext, patientId, gender } = this.props;
        const editableTypes = {
            'edit': { type: 'text' },
            'number': { type: 'number', inputMode: 'numeric', decimal: true },
            'spinner': { type: 'number', inputMode: 'numeric', decimal: true },
            'systolicBloodPressure': { type: 'number' },
            'diastolicBloodPressure': { type: 'number' },
            'temperature': { type: 'text', inputMode: 'numeric' },
            'oxygen': { type: 'number', inputMode: 'numeric' },
            'heartRate': { type: 'number', inputMode: 'numeric' },
            "date": { type: 'date', defaultValue: new Date() }
        };

        function hookDiv(ref) {
            if (ref) {
                ref.addEventListener('click', (e) => {
                    if (appContext.onURL && e.target.tagName === 'A') {
                        appContext.onURL({ href: e.target.href });
                        e.preventDefault();
                    }
                });
            }
        }

        const { response, question, checked, textLabels, answered, dialog, category } = this.state;
        const hasCategories = question.answers.some(i => i.category);
        const groupedAnswers = question.answers.filter((a) => !category || a.category === category).sort((a, b) => {
            if (!a.category || !b.category || a.category === '' || b.category === '') return 1;
            return 0;
        }).reduce((prev, next) => {
            const existing = next.category ? prev.find(i => i.category === next.category) : prev.find(i => i.category === '');
            if (existing) {
                const filtered = next.category ? prev.filter(i => i.category !== next.category) : prev.filter(i => i.category !== '');
                prev = [...filtered, { ...existing, answers: [...existing.answers, next] }];
            } else {
                prev.push({ category: next.category || '', answers: [next] })
            }
            return prev;
        }, []);
        const answers = groupedAnswers.map(group => {
            const answerList = group.answers.map((answer, i) => {
                const readOnly = !category && answer.category !== category;          // control should be readOnly if the answer is in a category and the category isn't selected
                let answerInfo = answer.explanation ? <Tooltip style={styles.answerInfo} PopperProps={{disablePortal: true}} enterTouchDelay={50} title={answer.explanation}><InfoIcon /></Tooltip> : '';
                let answerErrorText = answer.errorText ? <div key={`error${i}`} style={{ color: 'red' }}>{answer.errorText}</div> : '';
                let check = checked[answer.answerId];
                if (answer.controlType === 'patientData') {
                    const { dataId, tupleIndex, days } = answer.controlOptions;
                    if (textLabels[answer.answerId] === undefined) {
                        textLabels[answer.answerId] = answer.text[0].valueOf();
                        answer.text[0] = '';        // initialize to an empty string
                    }
                    let label = textLabels[answer.answerId];
                    return (<div key={i}>
                        <div style={styles.answerRow}>
                            <SelectPatientData
                                id={`${question.questionId}-${answer.answerId}`}
                                appContext={appContext}
                                disabled={answered}
                                patientId={patientId}
                                dataId={dataId}
                                tupleIndex={tupleIndex}
                                days={days}
                                label={label}
                                onChange={this.onChangeAnswerText.bind(this, i)} />
                        </div>
                    </div>);
                }
                else if (answer.controlType === 'uploadImage') {
                    if (textLabels[answer.answerId] === undefined) {
                        textLabels[answer.answerId] = answer.text[0].valueOf();
                        answer.text[0] = '';        // initialize to an empty string
                    }
                    let label = textLabels[answer.answerId];

                    // max image count has to account for our imaginary empty value for now
                    const { maxImageCount = 1 } = answer.controlOptions || {};
                    const imageCount = answer.text.reduce((p, v) => {
                        if (v) p += 1;
                        return p;
                    }, 0);
                    const disabled = imageCount >= maxImageCount || answered;
                    return (<div key={i}>
                        <UploadPatientImage
                            id={`${question.questionId}-${answer.answerId}`}
                            maxImageCount={maxImageCount}
                            ref={this.imageUploadRef}
                            appContext={appContext}
                            patientId={patientId}
                            disabled={disabled}
                            onChange={this.onChangeAnswerText.bind(this, i)}
                            hideUploads={!check}
                        >
                            {answer.text.map((blobId, j) => {
                                return (
                                    <PatientImage
                                        style={styles.uploadImage}
                                        key={j}
                                        appContext={appContext}
                                        blobId={check ? blobId : null}
                                        defaultImage={defaultImage}
                                        alt={label}
                                        square={false}
                                        enableDelete={!answered}
                                        onDelete={() => {
                                            if (answer.text.length <= 1) {
                                                answer.text[0] = '';
                                                checked[answer.answerId] = false;
                                            } else {
                                                answer.text.splice(j, 1)
                                            }
                                            this.forceUpdate();
                                        }}
                                    />
                                );
                            })}
                        </UploadPatientImage>
                    </div>
                    );
                }
                else if (answer.controlType === 'slider') {
                    const isMultiAnswer = question.multiSelect && question.answers.length > 1;
                    const { min = 1, max = 10, scale = 1 } = answer.controlOptions || {};

                    let markSteps = (Number(max) - Number(min)) / 5;

                    let marks = []
                    for (let i = Number(min); i <= Number(max); i = i + markSteps) {
                        const value = Math.round(i);
                        marks.push({
                            value,
                            label: String(value)
                        })
                    }

                    // initialize the first mark as the answer, when this control first appears
                    if (!checked[answer.answerId]) {
                        answer.label = answer.text[0];
                        answer.text[0] = `${marks[0].value}`;
                        checked[answer.answerId] = true;
                    }
                    return (<div key={i}>
                        <div style={styles.slider}>
                            {isMultiAnswer && <strong>{answer.label}</strong>}
                            <Slider
                                id={`${question.questionId}-${answer.answerId}`}
                                step={Number(scale)}
                                marks={marks}
                                min={Number(min)}
                                max={Number(max)}
                                valueLabelDisplay="auto"
                                value={Number(answer.text[0])}
                                disabled={answered}
                                onChange={this.onChangeSliderAnswerText.bind(this, i)}
                            />
                        </div>
                    </div>)
                }
                else if (answer.controlType === 'singleton') {
                    if (readOnly) {
                        return <div key={i}>
                            <div style={styles.answerRow}>
                                {/*<CheckIcon />*/}
                                <div ref={hookDiv} style={styles.answerReadOnly} dangerouslySetInnerHTML={{ __html: answer.text[0] }} />
                            </div>
                        </div>
                    }

                    return (<div key={i}>
                        <div style={styles.answerRow}>
                            <Button id={`${question.questionId}-${answer.answerId}`}
                                variant={check ? 'contained' : 'outlined'}
                                color={styles.answers.color}
                                style={styles.answerRowButton}
                                onClick={this.selectAnswer.bind(this, answer.answerId, check ? false : true)}>
                                <div ref={hookDiv} style={styles.answerRowAnswer} dangerouslySetInnerHTML={{ __html: answer.text[0] }} />
                            </Button>
                            {answerInfo}
                        </div>
                    </div>);
                } else if (answer.controlType === 'bodyMap') {
                    return (<div key={i}>
                        <div style={styles.answerRow}>
                            <BodyImageMap
                                active={!answered}
                                gender={gender}
                                onSelect={(areas) => {
                                    console.debug(`On select `, areas)
                                    if (Array.isArray(areas)) {
                                        let bodyAreas = areas.map(a => a.name);
                                        bodyAreas = bodyAreas.sort();
                                        bodyAreas = bodyAreas.join(",");
                                        this.onChangeBodyMapAnswerText(i, bodyAreas);
                                    }
                                }}
                            />
                            {answerInfo}
                        </div>
                    </div>);
                }
                else if (editableTypes[answer.controlType]) {
                    let editType = editableTypes[answer.controlType];
                    if (textLabels[answer.answerId] === undefined) {
                        textLabels[answer.answerId] = answer.text[0].valueOf();
                        answer.text[0] = editType.defaultValue || '';        // initialize to an empty string
                    }
                    let label = textLabels[answer.answerId];
                    let inputProps = editType.inputMode ? { inputMode: editType.inputMode } : {};
                    if (editType.decimal) inputProps = { ...inputProps, inputMode: 'decimal' }

                    if (readOnly) {
                        return <div key={i}>
                            <div style={styles.answerRow}>
                                <span style={styles.answerReadOnly}>{label}: {answer.text[0]}</span>
                            </div>
                        </div>
                    }

                    return (
                        <div key={i}>
                            <div style={styles.answerRowInput}>
                                <TextField id={`${question.questionId}-${answer.answerId}`}
                                    fullWidth
                                    type={editType.type}
                                    inputProps={inputProps}
                                    error={answer.errorText ? true : false}
                                    disabled={answered}
                                    style={styles.controlTypeInput}
                                    helperText={label}
                                    value={answer.text[0]}
                                    onChange={this.onChangeAnswerText.bind(this, i)}
                                />
                                {answerInfo}
                                {answerErrorText}
                            </div>
                        </div>
                    );
                }
                else {
                    if (readOnly) {
                        return <div key={i}>
                            <div style={styles.answerRow}>
                                <div ref={hookDiv} style={styles.answerReadOnly} dangerouslySetInnerHTML={{ __html: answer.text[0] }} />
                            </div>
                        </div>;
                    }
                    return (<div key={i}>
                        <div style={styles.answerRow}>
                            <Button id={`${question.questionId}-${group.category.split(" ", 1)}ID${answer.answerId}`}
                                variant={check ? 'contained' : 'outlined'}
                                color={styles.answers.color}
                                style={styles.answerRowButton}
                                onClick={this.selectAnswer.bind(this, answer.answerId, check ? false : true)}>
                                <div ref={hookDiv} style={styles.answerRowAnswer} dangerouslySetInnerHTML={{ __html: answer.text[0] }} />
                            </Button>
                            {answerInfo}
                        </div>
                    </div>);
                }
            });
            if (group.category && hasCategories && groupedAnswers.length > 1) {
                // When displaying the top level category, we only display the answers that have been answered..
                const checkedAnswers = answerList.filter((v, i) => checked[group.answers[i].answerId])
                return <div key={group.category} align='center' style={{ width: '100%' }}>
                    <div style={styles.answerRow}>
                        <Button id={`${question.questionId}-${group.category}`}
                            variant={checkedAnswers.length > 0 ? 'contained' : 'outlined'}
                            color={styles.answers.color}
                            style={styles.answerRowButton}
                            onClick={() => this.setState({ category: group.category })} >
                            <div style={{ width: '100%', paddingTop: 5, paddingBottom: 5 }}><b>{group.category}</b><br />
                                <div style={{ paddingLeft: 10, fontSize: 10, width: '100%' }}>
                                    {checkedAnswers}
                                </div>
                            </div>
                        </Button>
                    </div>
                </div>;
            }
            else {
                return answerList;
            }
        });

        let buttonLabel = this.canSkip() ? <T.span text='skip' /> : <T.span text="next" />;
        let submitDisabled = this.props.disabled || !this.canSubmit(); // (this.canSubmit() ? false : true);
        let cancelButton = !category && this.props.onCancel ? <Button id='cancel' color='primary' style={styles.button} onClick={this.props.onCancel}><T.span text='cancel' /></Button> : null;
        let backButton = !category && this.props.onBack ? <Button id={'back'} color='primary' style={styles.button} onClick={this.props.onBack}><T.span text='back' /></Button> : null;
        let doneButton = category ? <Button id='done' color='primary' style={styles.button} onClick={() => this.setState({ category: '' })}><T.span text='done' /></Button> : null;
        let nextButton = question.eod || category ? null : <Button id={question.questionId} disabled={submitDisabled} color="primary" style={styles.button} onClick={this.submitAnswers.bind(this)} >{buttonLabel}</Button>;
        let questionInfo = question.explanation ? <Tooltip enterTouchDelay={50} title={question.explanation} PopperProps={{disablePortal: true}}><InfoIcon /></Tooltip> : '';

        return <Dialog open={true}
            disableEnforceFocus
            style={{ pointerEvents: 'none' }}
            PaperProps={{ style: { pointerEvents: 'auto' } }}
            fullScreen={appContext.isCompactDisplay()}
            maxWidth='md'
            fullWidth={true}
            hideBackdrop={true}
            TransitionComponent={Slide}
            TransitionProps={{ direction: 'up' }} >
            <DialogContent>
                <Paper style={styles.paper}>
                    <div style={styles.responses} align='left' key={question.questionId}>
                        <div id={`qid-${question.questionId}`} style={styles.questionRow}>
                            <div ref={hookDiv} dangerouslySetInnerHTML={{ __html: response }} />
                            {questionInfo}
                        </div>
                    </div>
                    {answers}
                </Paper>
                {dialog}
            </DialogContent>
            <DialogActions style={{ justifyContent: category ? 'center' : 'space-between' }}>
                {cancelButton}
                {backButton}
                {doneButton}
                {nextButton}
            </DialogActions>
        </Dialog>
    }
};

export class ConversationView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            symptomTracking: props.symptomTracking || null,
            baseline: props.baseline || false,
            triage: props.triage || false,
            patientId: props.patientId,
            sessionId: props.sessionId || '',
            language: props.language || 'en-us',
            temperatureUnit: props.temperatureUnit || 'F',
            timezone: props.timezone || 'America/Chicago',
            eventTime: props.eventTime || null,
            verify: props.verify || false,
            models: props.models || [],
            hardReset: props.hardReset || false,
            source: props.source || [],
            userType: props.userType || undefined,
            reply: '',
            lastResponse: null,
            conversation: [],
            flags: [],
            journal: [],
            answeredQuestionIds: []               // ID's of questions already answered, so we don't display them a second time
        };
        log.debug("ConversationView()", this);
    }

    componentDidMount() {
        const { appContext, disabled } = this.props;
        log.debug("componentWillMount:", this);
        if (!disabled) {
            this.resetConversation();
        }

        if (appContext.registerMessageHandler) {
            appContext.registerMessageHandler(this.onMessage.bind(this));
            appContext.postMessage({ action: 'onConversationView' });
        }
    }

    componentWillUnmount() {
        const { appContext } = this.props;
        if (appContext.unregisterMessageHandler) {
            appContext.postMessage({ action: 'onConversationViewEnd' });
            appContext.unregisterMessageHandler(this.onMessage.bind(this));
        }
        if (this.props.onUnmount) {
            this.props.onUnmount();
        }
    }


    componentDidUpdate(oldProps) {
        if (oldProps.patientId !== this.props.patientId || (oldProps.disabled !== this.props.disabled && !this.props.disabled)) {
            this.resetConversation(true);
        }
    }

    onMessage(message) {
        try {
            if (typeof message.data === 'string') {
                log.debug("onMessage:", message);
                let msg = JSON.parse(message.data);
                if (msg.action === 'submitBluetoothValue')
                    this.submitBluetoothValue(msg.type, msg.value);
            }
        }
        catch (err) {
            log.warning("onMessage error:", err);
        }
    }

    // function to check if the current question has any BT answer types
    getBluetoothTypes() {
        let types = '';

        const { lastResponse } = this.state;
        if (lastResponse && lastResponse.question) {
            let question = lastResponse.question;
            for (let i = 0; i < question.answers.length; ++i) {
                let answer = question.answers[i];
                if (BLUETOOTH_CONTROL_TYPES.indexOf(answer.controlType) >= 0) {
                    if (types) types += ',';
                    types += answer.controlType;
                }
            }
        }

        log.debug("getBluetoothTypes:", types);
        return types;
    }

    submitBluetoothValue(type, value) {
        if (this.currentQuestion) {
            this.currentQuestion.submitBluetoothValue(type, value);
        }
    }

    handleResponse(res) {
        log.debug("handleResponse:", res);

        const { appContext } = this.props;
        let { patientId, temperatureUnit, symptomTracking, answeredQuestionIds } = this.state;
        let { answeredQuestions, totalQuestions } = res;
        if (this.props.onResponse) {
            this.props.onResponse(res, this);
        }

        window.scrollTo(0, 0);       // scroll back to the top on a response

        //let { conversation } = this.state;
        let conversation = [];
        let question = res.question;
        if (res.done && answeredQuestions !== totalQuestions) {
            answeredQuestions = totalQuestions;
        }

        if (res.done) {
            if (this.props.onDone) {
                this.props.onDone(res);
            } else {
                function hookDiv(ref) {
                    if (ref) {
                        ref.addEventListener('click', (e) => {
                            if (appContext.onURL && e.target.tagName === 'A') {
                                appContext.onURL({ href: e.target.href });
                                e.preventDefault();
                            }
                        });
                    }
                }

                conversation.unshift(<Paper key={conversation.length} >
                    <div style={styles.responses} align='left'>
                        <div style={styles.questionRow}>
                            <div ref={hookDiv} dangerouslySetInnerHTML={{ __html: res.response }} />
                        </div>
                    </div></Paper>);
            }
            if (this.props.onPostDone) {
                this.props.onPostDone(res);
            }
        }
        else {
            conversation = <SelectQuestion
                appContext={appContext}
                patientId={patientId}
                response={res.response}
                answeredQuestionIds={answeredQuestionIds}
                question={question}
                ref={(currentQuestion) => this.currentQuestion = currentQuestion}
                symptomTracking={symptomTracking}
                onSubmit={this.sendAnswers.bind(this)}
                onCancel={this.props.onCancel}
                temperatureUnit={temperatureUnit} />
        }

        console.log("conversation:", conversation);
        this.setState({ progress: null, lastResponse: res, conversation, questionProgress: { answeredQuestions, totalQuestions }, flags: res.flags });
        if (appContext.postMessage) {
            appContext.postMessage({ action: 'onBluetoothTypes', types: this.getBluetoothTypes() });
        }
    }

    sendAnswers(sendJournal) {
        log.debug("sendAnswers:", sendJournal);
        const { appContext } = this.props;
        const { patientId, baseline, triage, journal, language, temperatureUnit,
            timezone, eventTime, verify, answeredQuestionIds, source, userType } = this.state;

        const sendReply = {
            url: Config.baseUrl + `${Config.pathPrefix}dialog/conversation/${patientId}`,
            method: 'POST',
            headers: { "Authorization": appContext.state.idToken },
            data: {
                baseline, triage, journal: sendJournal, language, temperatureUnit, timezone, verify,
                eventTime: eventTime || undefined, source, userType
            }
        };

        journal.push(...sendJournal);
        for (let i = 0; i < sendJournal.length; ++i)
            answeredQuestionIds.push(sendJournal[i].questionId);

        log.debug("sendAnswers:", sendReply);
        this.setState({ progress: <CircularProgress size={PROGRESS_SIZE} />, error: null, journal, answeredQuestionIds });
        AxiosRequest(sendReply).then((response) => {
            log.debug("sendAnswers response:", response);
            this.handleResponse(response.data);
            //this.setState({ progress: null });
        }).catch((error) => {
            log.error("sendAnswers error:", error);
            this.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    resetConversation(hardReset = false, resetContext = false) {
        const { appContext } = this.props;
        resetContext = toBoolean(resetContext);
        hardReset = toBoolean(hardReset);

        this.setState({
            hardReset: (hardReset || this.props.hardReset || this.state.patientId !== this.props.patientId) || false,
            baseline: this.props.baseline || false,
            triage: this.props.triage || false,
            eventTime: this.props.eventTime || undefined,
            userType: this.props.userType || undefined,
            patientId: this.props.patientId,
            models: this.props.models || [],
            verify: this.props.verify || false,
            journal: [],
            flags: [],
            conversation: [],
            answeredQuestionIds: []
        }, () => {
            const { patientId, sessionId, baseline, triage, language, temperatureUnit, timezone,
                eventTime, models, verify, hardReset, source, userType } = this.state;
            if (patientId) {
                const resetConversation = {
                    url: Config.baseUrl + `${Config.pathPrefix}dialog/conversation/${patientId}`,
                    method: 'POST',
                    headers: { "Authorization": appContext.state.idToken },
                    data: {
                        baseline, triage, flags: [], language, temperatureUnit, timezone, reset: true, hardReset, verify,
                        eventTime: eventTime || undefined, resetContext, source, userType
                    }
                };
                if (Array.isArray(models) && models.length > 0)
                    resetConversation.data.models = models;
                if (sessionId)
                    resetConversation.data.sessionId = sessionId;

                log.debug("resetConversation:", resetConversation);
                this.setState({ progress: <CircularProgress size={PROGRESS_SIZE} />, hardReset: false, error: null });
                AxiosRequest(resetConversation).then((response) => {
                    log.debug("resetConversation response:", response);
                    this.handleResponse(response.data);
                    //this.setState({ progress: null });
                }).catch((error) => {
                    log.error("resetConversation error:", error);
                    this.setState({ progress: null, error: getErrorMessage(error) });
                });
            }
        });
    }

    onCloseDialog() {
        this.setState({ dialog: null });
    }

    render() {
        const { appContext, disabled } = this.props;
        const { conversation, progress, error, dialog } = this.state;

        const loadingSkeleton = () => <Dialog open={true}
            disableEnforceFocus
            style={{ pointerEvents: 'none' }}
            PaperProps={{ style: { pointerEvents: 'auto' } }}
            fullScreen={appContext.isCompactDisplay()}
            maxWidth='md'
            fullWidth={true}
            hideBackdrop={true}
            TransitionComponent={Slide}
            TransitionProps={{ direction: 'up' }} >
            <DialogContent>
                <div>
                    <Skeleton variant='rect' animation='wave' height={35} style={{ margin: 15 }} />
                    <br /><br /><br />
                    <Skeleton variant='rect' animation='wave' height={35} style={{ margin: 15 }} />
                    <Skeleton variant='rect' animation='wave' height={35} style={{ margin: 15 }} />
                    <Skeleton variant='rect' animation='wave' height={35} style={{ margin: 15 }} />
                    <Skeleton variant='rect' animation='wave' height={35} style={{ margin: 15 }} />
                </div>
            </DialogContent>
        </Dialog>;

        const errorDialog = () => <Dialog open={true} fullScreen={appContext.isCompactDisplay()} maxWidth='sm' fullWidth={true} TransitionComponent={Slide} TransitionProps={{ direction: 'up' }}>
            <DialogContent>
                {error}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => this.props.onDone(null)}><T.span text='ok' /></Button>
            </DialogActions>
        </Dialog>;

        return <Grid id={this.props.id} container align="center" style={{ marginTop: 50 }}>
            {disabled ? <T.span text="noPatientPlans" /> : error ? errorDialog() : progress ? loadingSkeleton() : conversation}
            {dialog}
        </Grid>;
    }
}

const styles = {
    answers: {
        color: '#404040',
    },
    drawer: {
        transitionDuration: 500
    },
    paper: {
        padding: 20,
        margin: 15
    },
    controlTypeInput: {
        maxWidth: 400,
        marginBottom: 10,
    },
    sectionSpacing: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 10,
        paddingTop: 0,
        marginLeft: 15,
        marginRight: 15,
        marginBottom: 15,
        marginTop: 0
    },
    resetSectionSpacing: {
        padding: 15,
        margin: 25
    },
    text: {
        margin: 10,
        width: '100%'
    },
    table: {
        margin: 10,
        width: '100%'
    },
    select: {
        margin: 10,
        width: '50%'
    },
    bodyMap: {
        margin: 10,
        width: '100%'
    },
    slider: {
        marginTop: 25,
        marginBottom: 25
    },
    button: {
        margin: 5,
        width: 100
    },
    submitButton: {
        marginBottom: 15,
    },
    div: {
        margin: 0
    },
    innerConversation: {
        padding: 10,
    },
    responses: {
        marginTop: 0,
        marginBottom: 15,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 20,
        paddingBottom: 10,
        fontSize: 25,
        //fontWeight: 'bold'
    },
    questionRow: {
        paddingTop: 10,
        paddingBottom: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    answerRow: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    answerRowInput: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    answerRowButton: {
        margin: 5,
        width: '89%',
        display: 'flex',
        justifyContent: 'flex-start',
        textAlign: 'left',
        fontSize: 18,
        fontWeight: 'bold'
        //textTransform: 'none'
    },
    answerRowCheckRadio: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    answerInfo: {
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: 18
    },
    answerReadOnly: {
        margin: 0,
        width: '89%',
        paddingTop: 2,
        paddingBottom: 2,
        textTransform: 'uppercase'
    },
    answerRowAnswer: {
        width: '100%',
        paddingTop: 5,
        paddingBottom: 5
    },
    edit: {
        margin: 10,
        width: '100%'
    },
    panel: {
        margin: 5
    },
    toolTip: {
        fontSize: 18
    },
    quesitonText: {

    },
    uploadImage: {
        margin: 16,
        border: '1px solid #ddd',
        padding: 16,
        minWidth: 220,
        minHeight: 220,
        boxSizing: 'border-box',
    },
}

export default ConversationView;
