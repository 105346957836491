import React from 'react';
import PropTypes from 'prop-types';

import { checkClientVersion } from '@apricityhealth/web-common-lib/utils/checkClientVersion';
import { Maintenance } from '@apricityhealth/web-common-lib/components/Maintenance';
import { Logger } from '@apricityhealth/web-common-lib';
//import { ConversationView } from '@apricityhealth/web-common-lib/views/ConversationView';
import { ConversationView } from './views/NewConversationView';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';
import BaselineView from '@apricityhealth/web-common-lib/views/BaselineView';
import LoginView from '@apricityhealth/web-common-lib/views/LoginView';
//import LoginView from './views/LoginView';

import { SelectSelf } from './components/SelectSelf';
import { CreateAccountDialog } from './dialogs/CreateAccountDialog';
import { TermsDialog } from './dialogs/TermsDialog';
import { EodDialog } from './dialogs/EodDialog';

import { DashboardView, isVisible, isDisabled, getSettings } from './views/DashboardView';

import { SurveyView } from './views/SurveyView';
import { TrialsView } from './views/TrialsView';
import { SettingsView } from './views/SettingsView';
import { ProfileView } from './views/ProfileView';
import { ClearanceView } from './views/ClearanceView';
import { VideoChatView } from './views/VideoChatView';
import { MessagesView } from './views/MessagesView';
import { ReportView } from './views/ReportView';
import { AppointmentsView } from './views/AppointmentsView';
import { FeedbackView } from './views/FeedbackView';
import { ContentView } from './views/ContentView';
import { VaccinationsView } from './views/VaccinationsView';
import CovidTestsView from './views/CovidTestsView';
import { EducationView } from './views/EducationView';
import { WebView } from './views/WebView';

import { getTheme } from './themes/manifest';
import { HotSpotsView } from './views/HotSpotsView';

import ApricityCareLogo from './apricityCareLogo.png';
import ApricityWorkLogo from './apricityWorkLogo.png';
import AndroidStoreImage from './android.png';
import iOSStoreImage from './ios.png';

import DashboardIcon from '@material-ui/icons/Dashboard';
import EducationIcon from '@material-ui/icons/OndemandVideo';
import ConversationIcon from '@material-ui/icons/VerifiedUser';
import SurveysIcon from '@material-ui/icons/Poll';
import TrialsIcon from '@material-ui/icons/Poll';
import SettingsIcon from '@material-ui/icons/Settings';
import ProfileIcon from '@material-ui/icons/Person';
import SignOutIcon from '@material-ui/icons/ExitToApp';
import VideoIcon from '@material-ui/icons/Videocam';
import MessagesIcon from '@material-ui/icons/Email';
import ReportIcon from '@material-ui/icons/Description';
import AppointmentsIcon from '@material-ui/icons/Today';
import NavigationMenu from '@material-ui/icons/Menu';
import CallIcon from '@material-ui/icons/Call';
import HomeIcon from '@material-ui/icons/Home';
import FAQIcon from '@material-ui/icons/Assignment';
import FeedbackIcon from '@material-ui/icons/Feedback';
//import PersonIcon from '@material-ui/icons/Person';
import WebIcon from '@material-ui/icons/Web';
import ClearanceIcon from '@material-ui/icons/ClearAll';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import SunIcon from '@material-ui/icons/WbSunny';
import VaccinationIcon from '@material-ui/icons/HowToReg';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import CheckInIcon from '@material-ui/icons/VerifiedUser';
import AddBoxIcon from '@material-ui/icons/AddBox';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import PinDropRoundedIcon from '@material-ui/icons/PinDropRounded';

import { blue } from '@material-ui/core/colors';

import { toBoolean } from './utils';

import PWAPrompt from 'react-ios-pwa-prompt'
import IdleTimer from 'react-idle-timer';
import Moment from 'moment-timezone';
import Phone from 'phone';

import 'moment/locale/fr.js';
import 'moment/locale/es.js';
import 'moment/locale/zh-cn.js';
import 'moment/locale/zh-tw.js';

import { languages } from '@apricityhealth/web-common-lib/components/SelectLanguage';

import T from 'i18n-react';
import _ from 'lodash';

import {
    withStyles,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    Dialog,
    Button,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Drawer,
    MenuList,
    ClickAwayListener,
    Paper,
    CircularProgress,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    Badge,
    Snackbar,
    SnackbarContent,
    TextField
} from '@material-ui/core';

import { ThemeProvider } from '@material-ui/core/styles';
import { Switch, Route, Redirect, Link, NavLink } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';

import './App.css';
import { addActivity } from '@apricityhealth/web-common-lib/utils/Services';
import { SubscriptionDialog } from 'dialogs/SubscriptionDialog';
import { ReminderDialog } from 'dialogs/ReminderDialog';
import { TutorialDialog } from 'dialogs/TutorialDialog';

const log = new Logger();
const CLIENT_VERSION = require('../package.json').version;
const DEFAULT_LANGUAGE = 'en-us';

log.update({ clientName: 'patient-web-client', clientVersion: CLIENT_VERSION });

// ms, sec, min
const IDLE_TIMEOUT_MS = (Config.stage === 'local' || Config.stage === 'develop') ? 1000 * 60 * 60 : 1000 * 60 * 15;

const LOGO_OVERRIDES = [
    {
        override: "Apricity@Work",
        planIds: ["63575a2f-2c1e-4e2f-a0e2-a908a1ae0354", "f318684a-71c9-4e52-af2b-f58205c43f55"]
    },
    {
        override: "ApricityCare"
    }
];

const NATIVE_LINKS = [
    {
        "url": "https://play.google.com/store/apps/details?id=com.apricityhealth.apricityatwork",
        "device": "android",
        "planIds": ["63575a2f-2c1e-4e2f-a0e2-a908a1ae0354", "f318684a-71c9-4e52-af2b-f58205c43f55"]
    },
    {
        "url": "https://itunes.apple.com/us/app/apricitycare/id1521331738",
        "device": "ios",
        "planIds": ["63575a2f-2c1e-4e2f-a0e2-a908a1ae0354", "f318684a-71c9-4e52-af2b-f58205c43f55"]
    },
    // default links for all other plans
    {
        "url": "https://play.google.com/store/apps/details?id=com.apricityhealth.patient",
        "device": "android"
    },
    {
        "url": "https://itunes.apple.com/us/app/apricitycare/id1436081572",
        "device": "ios"
    }
];

function getQuery() {
    return new URLSearchParams(window.location.search);
}

function getAppName() {
    // prefer the query parameter over local storage at all times...
    let appName = getQuery().get('appName');
    if (! appName ) {
        appName = sessionStorage.getItem('appName');
        if (! appName ) {
            // last resort, just check the stage and default to either ApricityCare for all env's except for production..
            appName = Config.stage === 'production' ? "Apricity@Work" : "ApricityCare";
        }
    } else {
        sessionStorage.setItem('appName', appName );
    }
    return appName;
}

function isHTML(str) {
    return str.indexOf('<br>') >= 0 || str.indexOf('<ul>') >= 0 || str.indexOf('<p>') >= 0;
}

function isReactNative() {
    return window.ReactNativeWebView ? true : false;
}

function isArrayValid(array) {
    return Array.isArray(array) && array.length > 0;
}

function getHostPrefix() {
    let host = window.location.host;
    let colon = host.indexOf(':');
    if (colon >= 0)
        host = host.substring(0, colon);
    let dot = host.indexOf('.');
    if (dot >= 0)
        host = host.substring(0, dot);
    return host;
}

class IdleDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            countDown: 60
        };
    }

    componentDidMount() {
        this.intervalTimer = setInterval(() => {
            let { countDown } = this.state;
            countDown -= 1;
            if (countDown < 0)
                this.props.appContext.confirmSignOut();
            else
                this.setState({ countDown });
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.intervalTimer);
    }

    onCancel() {
        this.props.appContext.setState({ idleDialog: null });
    }

    render() {
        let { countDown } = this.state;
        return <Dialog id="idleTimeout" open={true} onClose={this.onCancel.bind(this)} fullWidth={true} maxWidth={'xs'} style={{ background: 'rgba(0,0,0,0.6)' }}>
            <DialogContent>
                <T.span text={{ key: 'idleTimeout', countDown }} />
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={this.onCancel.bind(this)}><T.span text='cancel' /></Button>
            </DialogActions>
        </Dialog>
    }
}

class App extends React.Component {
    constructor(props) {
        super(props);

        log.debug("stage:", Config.stage);

        const timezone = Moment.tz.guess();
        log.debug("timezone:", timezone);

        Moment.updateLocale("es", { week: { dow: 0 } })
        Moment.updateLocale("fr", { week: { dow: 0 } })

        let language = localStorage.getItem('language') || (navigator.languages && navigator.languages[0])
            || navigator.language || navigator.userLanguage;
        language = language.toLowerCase();      // normalize to lower-case
        if (!languages[language]) {
            language = DEFAULT_LANGUAGE;         // not in our list of supported languages, fallback to the default language
        }

        Moment.locale(language);

        log.debug("language:", language);
        let temperatureUnit = localStorage.getItem('temperatureUnit') || 'F';
        log.debug("temperatureUnit:", temperatureUnit);

        this.appTexts = {};
        this.appTexts[language] = require(`./locale/${language}.json`);

        this.state = {
            version: CLIENT_VERSION,
            username: '',
            idToken: null,
            refreshToken: null,
            currentView: null,
            menu: null,
            dialog: null,
            openDialog: null,
            patient: null,
            lastBaseline: null,
            patientId: '',
            theme: 'light',
            language,
            temperatureUnit,
            timezone,
            registerDone: false,
            domainDetected: false,
            termsAccepted: false,
            config: {},
            textLoaded: false,
            configLoaded: false,
            leftNavOpen: false,
            alertLevelsLoaded: false,
            alert_levels: [],
            domainOrg: null,
            defaultProviderId: '',
            defaultPlanId: Config.defaultPlanId,
            faq: [],
            companyPolicy: [],
            tutorials: [],
            isReactNative: isReactNative(),
            biometricLoginEnabled: false,
            contactTracingEnabled: false,
            lastSessionId: '',
            cache: {},
            surveys: [],
            messageCount: 0,
            hideSubscribeSnackbar: false,
            hideReminderSnackbar: false,
            hideSurveySnackbar: false,
            forcedCheckInReminderMessage: false,
            subscriptionId: '',
            removeAccount: false,
            removeAccountConfirmation: ''
        }
    }

    isCompactDisplay() {
        return window.innerWidth < 640;
    }

    componentDidMount() {
        let { defaultPlanId, defaultProviderId } = this.state;

        // check our client version against the back-end, this should reload this client if needed.
        checkClientVersion().then(() => {
            const query = getQuery();

            const hostPrefix = getHostPrefix();
            const thisUrl = window.location.protocol + '//' + window.location.host;
            if (thisUrl !== Config.patientClientUrl && Config.stage !== 'local' && hostPrefix !== 'localhost') {
                query.set('hostPrefix', hostPrefix);
                // open the actual client URL, pass in the hostPrefix so we can look up the domain org.. 
                window.location.href = Config.patientClientUrl + '?' + query.toString();
            } else {
                const removeAccount = toBoolean(query.get('removeAccount'))
                const subscriptionId = query.get("subscriptionId");
                if (subscriptionId) {
                    this.setState({ subscriptionId, patientId: query.get("patientId") });
                }

                const hostPrefix = query.get('hostPrefix') || localStorage.getItem('hostPrefix') || getHostPrefix();
                localStorage.setItem('hostPrefix', hostPrefix);            // keep using the same hostprefix until they provide a new one

                const getDomainOrg = {
                    url: Config.baseUrl + `${Config.pathPrefix}anon/orgs/domain/${hostPrefix}`,
                    method: 'GET',
                    maxAttempts: 0      // don't retry on this call, if the back-end is down we don't want to take forever with a blank screen
                };
                document.title = getAppName();
                console.log("getDomainOrg request:", getDomainOrg);
                AxiosRequest(getDomainOrg).then((response) => {
                    let domainOrg = isArrayValid(response.data) && response.data[0];
                    console.log("getDomainOrg response:", response.data);
                    if (!domainOrg) throw new Error("No domain org!");
                    let defaultPlan = domainOrg.planIds ? domainOrg.planIds.find((e) => e.default === true) : null;
                    if (defaultPlan)
                        defaultPlanId = defaultPlan.planId;
                    if (domainOrg.defaultProviderId)
                        defaultProviderId = domainOrg.defaultProviderId;
                    if (domainOrg.name) {
                        document.title = domainOrg.name;
                    }
                    let androidLink = NATIVE_LINKS.find((e) => {
                        return e.device === 'android' && (!Array.isArray(e.planIds) || e.planIds.indexOf(defaultPlanId) >= 0);
                    });
                    let iosLink = NATIVE_LINKS.find((e) => {
                        return e.device === 'ios' && (!Array.isArray(e.planIds) || e.planIds.indexOf(defaultPlanId) >= 0);
                    });
                    if (domainOrg.customLogo) {
                        console.log("Setting logoOverride:", domainOrg.customLogo);
                        localStorage.setItem("logoOverride", domainOrg.customLogo);
                    }
                    // } else if (!localStorage.getItem("logoOverride")) {
                    //     let logoOverride = LOGO_OVERRIDES.find((e) => {
                    //         return !Array.isArray(e.planIds) || e.planIds.indexOf(defaultPlanId) >= 0;
                    //     });
                    //     if (logoOverride) {
                    //         console.log("Setting logoOverride:", logoOverride.override);
                    //         localStorage.setItem("logoOverride", logoOverride.override);
                    //     }
                    // }

                    log.info(`defaultPlanId: ${defaultPlanId}, defaultProviderId: ${defaultProviderId}, domainOrg:`, domainOrg);
                    this.setState({ removeAccount, domainDetected: true, defaultPlanId, defaultProviderId, domainOrg, androidLink, iosLink }, this.checkRegistrationCode.bind(this))
                }).catch((err) => {
                    log.info("getDomainOrg error:", err);
                    this.setState({ removeAccount, domainDetected: true }, this.checkRegistrationCode.bind(this));
                })
            }

        }).catch((err) => {
            console.error("checkClientVersion error:", err);
        });


        this.registerMessageHandler(this.onMessage.bind(this));
        window.addEventListener('resize', this.onHandleResize.bind(this));
    }

    checkRegistrationCode() {
        const query = getQuery();
        if (query.get('registrationCode') || toBoolean(query.get('iframe')))      // remove the check for iFrame to open the register page, we need that flag for the forgot password page as well
            this.onRegister();                  // open the register dialog automatically when there is a registration code
    }

    onHandleResize() {
        if (this.isCompactDisplay()) {
            this.setState({ menu: null, leftNavOpen: false });       // hide the menu
        } else {
            this.setState({ menu: this.getMenu(), leftNavOpen: true });   // show the menu
        }
    }

    componentWillUnmount() {
        this.unregisterMessageHandler(this.onMessage.bind(this));
        window.removeEventListener("resize", this.onHandleResize.bind(this));
    }

    postMessage(message) {
        if (isReactNative()) {
            window.ReactNativeWebView.postMessage(JSON.stringify(message));
        }
    }

    postParentMessage(message) {
        if (window.parent) {
            window.parent.postMessage(JSON.stringify(message), '*');
        }
    }

    registerMessageHandler(handler) {
        window.addEventListener('message', handler);
        document.addEventListener('message', handler);
    }

    unregisterMessageHandler(handler) {
        window.removeEventListener('message', handler);
        document.removeEventListener('message', handler);
    }

    onMessage(message) {
        try {
            if (typeof message.data === 'string') {
                if ( message.data ) {
                    let msg = JSON.parse(message.data);
                    if ( msg.action ) {
                        //log.debug("onMessage:", msg );
                        if (msg.action === 'setEnableBiometricLogin') {
                            this.setState({ biometricLoginEnabled: msg.enabled });
                        }
                        else if (msg.action === 'setContactTracingEnabled') {
                            this.setState({ contactTracingEnabled: msg.enabled });
                        }
                        else if (msg.action === 'idleMinutes') {
                            if ((1000 * 60 * msg.idleMinutes) > IDLE_TIMEOUT_MS)
                                this.confirmSignOut();
                        } else if (msg.action === 'reloadPatient') {
                            // this message is sent by the iframe back to the main app so we know to reload the patient from the back-end
                            this.reloadPatient();
                        } else if (msg.action === 'onLog') {
                            log[msg.logLevel || 'info']( ...msg.logs );
                        } 
                    }
                }
            }
        }
        catch (err) {
            log.warning("onMessage error:", err, message);
        }
    }

    getSelf() {
        // grab our patientId
        const url = Config.baseUrl + `${Config.pathPrefix}patients/self`;
        const getSelf = {
            url: url,
            method: 'GET',
            headers: { "Authorization": this.state.idToken }
        }

        const startTime = Date.now();
        log.debug("getSelf:", url);
        AxiosRequest(getSelf).then((response) => {
            log.info(`getSelf result, ${Date.now() - startTime} ms:`, response.data);
            const patient = response.data;
            if (Array.isArray(patient)) {
                this.setState({
                    progress: null,
                    dialog: <SelectSelf
                        patients={patient}
                        onSelect={(patient) => {
                            if (patient) {
                                this.setState({ dialog: null }, this.onSelfSelected.bind(this, patient));
                            }
                            else {
                                this.setState({ dialog: null, progress: <p>Login cancelled.</p> }, this.confirmSignOut.bind(this));
                            }
                        }} />
                });
            } else {
                if (patient.org && patient.org.inactive) throw Error('inactiveOrg');
                this.onSelfSelected(patient);
            }

        }).catch((error) => {
            const appName = getAppName(); 
            const otherAppName = appName === 'ApricityCare' ? 'Apricity@Work' : 'ApricityCare';
            log.warning("getSelf error:", error);
            this.setState({
                dialog: <Dialog id="noRecordFound" open={true} fullWidth={true} maxWidth={'xs'} style={{ background: 'rgba(0,0,0,0.6)' }}>
                    <DialogTitle>{error.message === 'inactiveOrg' ? <T.span text='inactiveOrg' /> : <T.span text='noRecordFound' />}</DialogTitle>
                    <DialogContent>{error.message === 'inactiveOrg' ? <T.span text='inactiveOrgMessage' /> : <T.span text={{ key: 'noPatientRecord', appName, otherAppName }} />}</DialogContent>
                    <DialogActions><Button id="noPatientRecordOk" variant='contained' color='primary' onClick={this.confirmSignOut.bind(this)}><T.span text='ok' /></Button></DialogActions>
                </Dialog>
            });
        });

    }

    onSetLanguage(language) {
        if (!languages[language]) throw new Error("Unsupported language");
        localStorage.setItem('language', language);
        Moment.locale(language)

        this.updatePatient({ language });
        this.setState({ language }, this.updateLanguage.bind(this));
    }

    updateLanguage() {
        const { language } = this.state

        this.appTexts[language] = require(`./locale/${language}.json`);
        this.postMessage({ action: 'onSetLanguage', language });

        this.getTexts().finally(() => {
            this.getFAQ()
            this.getCompanyPolicy();
            this.refreshMenu();
        });
    }

    onSetTemperatureUnit(unit) {
        if (unit !== 'F' && unit !== 'C') throw new Error("Unsupported temperatureUnit");
        localStorage.setItem("temperatureUnit", unit);
        this.setState({ temperatureUnit: unit });
    }

    getPlanIds(patient) {
        return patient && Array.isArray(patient.plans) ? patient.plans.map((e) => e.planId) : [];
    }

    getCountry(patient) {
        if (patient) {
            let address = patient.address.find((e) => e.country !== undefined);
            if (address) return address.country;
        }
        return 'US';        // default to United States
    }

    onSelfSelected(patient) {
        log.debug(`onSelfSelected, patientId: ${patient.patientId}:`, patient);
        const { patientId, provider, org, user, gender, lastBaseline, planModels } = patient;
        const providerPhones = provider ? provider.phoneNumbers : [];
        let orgContact = org ? org.contacts.find((e) => e.contactId === 'default') : null;
        if (org && !orgContact && org.contacts.length > 0)
            orgContact = org.contacts[0];       // no default contact found, just use the first contact then..
        const orgPhones = orgContact ? orgContact.phoneNumbers : [];

        //patient.reminderEnabled = undefined;           // uncomment to debug SMS reminder

        // update the logoOverride based on the default plan of the org
        if ( org.customLogo ) {
            console.log("Setting logoOverride:", org.customLogo);
            localStorage.setItem("logoOverride", org.customLogo );
        } else {
            let defaultPlan = org && org.planIds ? org.planIds.find((e) => e.default === true) : null;
            if (defaultPlan) {
                let defaultPlanId = defaultPlan.planId;
                let logoOverride = LOGO_OVERRIDES.find((e) => {
                    return !Array.isArray(e.planIds) || e.planIds.indexOf(defaultPlanId) >= 0;
                });
                if (logoOverride) {
                    console.log("Setting logoOverride:", logoOverride.override);
                    localStorage.setItem("logoOverride", logoOverride.override);
                }
            }
        }
        let planActive = (patient.plans || []).reduce((p,c) => {
            const plan = (planModels || []).find((e) => e.planId === c.planId);
            if (plan && plan.inactive) {
                return p;
            }
            if ( !c.startDate || Moment(c.startDate) < Moment() ) {
                if (! c.endDate || Moment(c.endDate) > Moment() ) {
                    return true;
                }
            }
            return p;
        }, false );
        console.log("planActive:", planActive, patient.plans, planModels );

        if (org && org.enableMFA && !user.mfaEnabled) {
            user.mfaEnabled = true;
            saveUser(this.state.idToken, user).then((result) => {
                log.debug("MFA enabled for user account:", user);
            }).catch((err) => {
                log.error("Error enabling MFA for account:", user);
            });
        }

        this.setState({
            patientId,
            gender,
            patient,
            planModels,
            planActive,
            lastBaseline,
            user,
            account: user,          // TODO: account is deprecated, remove it slowly over time and use user instead
            provider,
            providerPhones,
            providerLoaded: true,
            org,
            orgPhones,
            orgLoaded: true,
            cache: {}
        }, () => {
            let startTime = Date.now();
            this.updatePatient({
                previousLogin: patient.lastLogin,
                lastLogin: new Date(),
                loginCount: (patient.loginCount || 0) + 1
            });

            Promise.all([
                this.getTexts(),
                this.getAlertLevels(),
                this.getConfig(),
                this.getPatientSurveys(),
                this.getPatientTrials(),
                this.getTutorials()
            ]).then(() => {
                log.debug(`Loaded text, provider, org, config, and alerts in ${Date.now() - startTime} ms`);
                this.displayTermsDialog();
                return Promise.all([this.getFAQ(), this.getCompanyPolicy()]);
            }).then(() => {
                this.refreshMenu();
            }).catch((err) => {
                const message = getErrorMessage(err);
                console.error("onSelfSelected error:", message );
                this.setState({
                    dialog: <Dialog id="appError" open={true} fullWidth={true} maxWidth={'xs'} style={{ background: 'rgba(0,0,0,0.6)' }}>
                        <DialogTitle><T.span text='clientErrorTitle' /></DialogTitle>
                        <DialogContent><T.span text={{ key: 'clientError', message }} /></DialogContent>
                        <DialogActions><Button id="noPatientRecordOk" variant='contained' color='primary' onClick={this.confirmSignOut.bind(this)}><T.span text='ok' /></Button></DialogActions>
                    </Dialog>
                });
                })
        })
    }

    displayTermsDialog() {
        const { org, patient, config, language, planActive } = this.state;
        let dialog = planActive ? 
            isArrayValid(config.termIds) ? <TermsDialog
                appContext={this}
                org={org}
                planIds={this.getPlanIds(patient)}
                termIds={config.termIds}
                language={language}
                country={this.getCountry(patient)}
                onAccepted={this.onAcceptedTerms.bind(this)}
                onRejected={this.onRejectedTerms.bind(this)} /> 
                : null 
            : <Dialog open={true}>
                <DialogTitle><T.span text='noPlan' /></DialogTitle>
                <DialogContent><T.span text="noPatientPlans" /></DialogContent>
                <DialogActions>
                    <Button variant='contained' color="primary" onClick={this.onAcceptedTerms.bind(this)}><T.span text='ok' /></Button>
                </DialogActions>
            </Dialog>;

        if ( dialog !== null ) {
            this.setState({
                progress: null,
                dialog
            });
        } else {
            this.onAcceptedTerms();
        }
    }

    getTexts() {
        const startTime = Date.now();
        return new Promise((resolve, reject) => {
            const { patient, idToken, language } = this.state
            let planIds = this.getPlanIds(patient);
            let promises = []
            planIds.forEach((planId) => {
                let cacheKey = `texts.${planId}.${language}`;
                let cached = localStorage.getItem(cacheKey);
                if (cached) {
                    cached = JSON.parse(cached);
                    if (cached.version === CLIENT_VERSION) {
                        promises.push(cached.data);
                    } else {
                        cached = null;      // old version, do a new request to the back-end
                    }
                }
                if (!cached) {
                    const request = {
                        url: Config.baseUrl + `${Config.pathPrefix}content/text?planId=${planId}&category=common,patient.web&dependencies=true&language=${language}`,
                        method: 'GET',
                        headers: { "Authorization": idToken }
                    }
                    log.debug("getTexts:", request);
                    promises.push(AxiosRequest(request).then((result) => {
                        localStorage.setItem(cacheKey, JSON.stringify({ version: CLIENT_VERSION, data: result.data.text }));
                        return result.data.text;
                    }));
                }
            })

            Promise.all(promises).then((results) => {
                log.debug(`getTexts results, ${Date.now() - startTime} ms:`, results);
                if (this.appTexts[language] === undefined)
                    this.appTexts[language] = {};

                results.forEach((result) => {
                    for (let i = 0; i < result.length; ++i) {
                        let text = result[i];
                        this.appTexts[language][text.textId] = text.text;
                    }
                });
                this.setState({ textLoaded: true });
                resolve()
            }).catch((err) => {
                console.error("getTexts error:", err);
                setTimeout(() => this.getTexts().then(resolve).catch(reject), 1000);      // re-try in 1 second
            })
        })
    }

    getFAQ() {
        const startTime = Date.now();
        return new Promise((resolve, reject) => {
            let planIds = this.getPlanIds(this.state.patient);
            log.debug("planIds:", planIds);
            if (planIds.length > 0) {
                const { org, language } = this.state;
                let faqTextId = _.get(org, "config.faqTextId") || 'faq';
                let cacheKey = `faq.${faqTextId}.${language}.${planIds.join(',')}`;
                let cached = localStorage.getItem(cacheKey);
                if (cached) {
                    cached = JSON.parse(cached);
                    if (cached.version === CLIENT_VERSION) {
                        return this.setState({ faq: cached.data }, resolve);
                    }
                }

                let queryParams = [
                    `textId=${faqTextId}`,
                    `language=${language}`,
                    "planId=" + planIds.join(',')
                ];
                let getFAQ = {
                    url: Config.baseUrl + `${Config.pathPrefix}content/text?` + queryParams.join('&'),
                    method: 'GET',
                    headers: { "Authorization": this.state.idToken },
                }
                log.debug("getFAQ:", getFAQ);
                AxiosRequest(getFAQ).then((response) => {
                    log.debug(`getFAQ result, ${Date.now() - startTime} ms:`, response.data.text);
                    localStorage.setItem(cacheKey, JSON.stringify({ version: CLIENT_VERSION, data: response.data.text }));      // save to local storage and save the call next time
                    this.setState({ faq: response.data.text }, resolve);
                }).catch((err) => {
                    console.error("getFAQ error:", err);
                    setTimeout(() => this.getFAQ().then(resolve).catch(reject), 1000);      // re-try in 1 second
                })
            }
            else {
                resolve();
            }
        });
    }

    getCompanyPolicy() {
        const { language } = this.state;
        const { org } = this.state;

        let companyPolicy = _.get(org, `config.companyPolicyPage_${language}`) || _.get(org, 'config.companyPolicyPage_en-us');
        log.debug("companyPolicy:", companyPolicy);
        if (companyPolicy)
            this.setState({ companyPolicy: [{ text: companyPolicy }] });
    }

    getConfig() {
        const startTime = Date.now();
        return new Promise((resolve, reject) => {
            let planIds = this.getPlanIds(this.state.patient);
            if (planIds.length > 0) {
                let promises = [];
                for (let i = 0; i < planIds.length; ++i) {
                    let planId = planIds[i];
                    let cacheKey = `config.${planId}`;
                    let cached = localStorage.getItem(cacheKey);
                    if (cached) {
                        cached = JSON.parse(cached);
                        if (cached.version === CLIENT_VERSION) {
                            promises.push(Promise.resolve(cached.data));
                        } else {
                            cached = null;
                        }
                    }
                    if (!cached) {
                        let getConfig = {
                            url: Config.baseUrl + `${Config.pathPrefix}types/${planId}/configs/patientConfig`,
                            method: 'GET',
                            headers: { "Authorization": this.state.idToken },
                        }

                        log.debug("getConfig:", getConfig);
                        promises.push(AxiosRequest(getConfig).then((result) => {
                            localStorage.setItem(cacheKey, JSON.stringify({ version: CLIENT_VERSION, data: result.data }));
                            return result.data;
                        }));
                    }
                }
                Promise.all(promises).then((results) => {
                    log.debug(`getConfig results, ${Date.now() - startTime} ms:`, results);
                    let config = {};
                    for (let i = 0; i < results.length; ++i) {
                        let configs = results[i];
                        for (let i = 0; i < configs.length; ++i)
                            _.merge(config, JSON.parse(configs[i].config));
                    }
                    log.debug("config loaded:", config);

                    // validate the language we have selected is in the list found in the config.
                    if (config.languages) {
                        if (!config.languages[this.state.language]) {
                            log.warning(`Selected language not found in the config, changing to the default language.`);
                            this.onSetLanguage(DEFAULT_LANGUAGE);
                        }
                    }

                    this.setState({ config, configLoaded: true }, resolve);
                }).catch((err) => {
                    console.error("getConfig error:", err);
                    setTimeout(() => this.getConfig().then(resolve).catch(reject), 1000);      // re-try in 1 second
                })

            }
            else {
                log.debug('No planIds, so no configs to load.');
                this.setState({ configLoaded: true }, resolve);
            }
        })
    }

    getAlertLevels() {
        const startTime = Date.now();
        return new Promise((resolve, reject) => {
            let planIds = this.getPlanIds(this.state.patient);
            if (planIds.length > 0) {
                let promises = [];
                for (let i = 0; i < planIds.length; ++i) {
                    let planId = planIds[i];
                    let cacheKey = `alerts.${planId}`;
                    let cached = localStorage.getItem(cacheKey);
                    if (cached) {
                        cached = JSON.parse(cached);
                        if (cached.version === CLIENT_VERSION) {
                            promises.push(cached.data);
                        } else {
                            cached = null;
                        }
                    }
                    if (!cached) {
                        let getAlertLevels = {
                            url: Config.baseUrl + `${Config.pathPrefix}types/${planId}/alert_levels/*`,
                            method: 'GET',
                            headers: { "Authorization": this.state.idToken },
                        }
                        log.debug("getAlertLevels:", getAlertLevels);
                        promises.push(AxiosRequest(getAlertLevels).then((result) => {
                            localStorage.setItem(cacheKey, JSON.stringify({ version: CLIENT_VERSION, data: result.data }));
                            return result.data;
                        }));
                    }
                }

                Promise.all(promises).then((results) => {
                    log.debug(`getAlertLevels results, ${Date.now() - startTime} ms:`, results);

                    let alert_levels = [];
                    for (let i = 0; i < results.length; ++i) {
                        alert_levels = alert_levels.concat(results[i]);
                    }
                    this.setState({ alert_levels, alertLevelsLoaded: true }, resolve);
                }).catch((err) => {
                    console.error("getAlertLevels error:", err);
                    setTimeout(() => this.getAlertLevels().then(resolve).catch(reject), 1000);      // re-try in 1 second
                })
            }
            else {
                this.setState({ alert_levels: [], alertLevelsLoaded: true });
                resolve();
            }
        })
    }

    getPatientSurveys() {
        const { idToken, patientId } = this.state;

        return new Promise((resolve, reject) => {
            let getPatientSurveys = {
                url: Config.baseUrl + `${Config.pathPrefix}dialog/surveys/${patientId}`,
                method: 'POST',
                headers: { "Authorization": idToken },
                data: {}
            };

            log.debug("getPatientSurveys:", getPatientSurveys);
            AxiosRequest(getPatientSurveys).then((response) => {
                log.debug("getPatientSurveys result:", response.data);
                this.setState({ surveys: response.data }, () => resolve(response.data));
            }).catch((err) => {
                console.error("getPatientSurveys error:", err);
                reject(err);
            })
        })
    }

    getPatientTrials() {
        const { idToken, patientId } = this.state;

        return new Promise((resolve, reject) => {
            let getPatientTrials = {
                url: Config.baseUrl + `${Config.pathPrefix}trials/patient/${patientId}?all=true`,
                method: 'GET',
                headers: { "Authorization": idToken }
            };

            log.debug("getPatientTrials:", getPatientTrials);
            AxiosRequest(getPatientTrials).then((response) => {
                log.debug("getPatientTrials result:", response.data);
                this.setState({ trials: response.data.patientTrials }, () => resolve(response.data.patientTrials));
            }).catch((err) => {
                console.error("getPatientTrials error:", err);
                reject(err);
            })
        })
    }

    getTutorials() {
        const { idToken, patientId, language } = this.state;
        return new Promise((resolve, reject) => {
            const request = {
                url: Config.baseUrl + `${Config.pathPrefix}content/education/${patientId}?language=${language}&tutorial=true&conditional=true`,
                method: 'GET',
                headers: { "Authorization": idToken},
            }
    
            log.debug(`getTutorials request`, request);
            AxiosRequest(request).then((response) => {
                log.debug(`getTutorials response`, response.data);
                this.setState( { tutorials: response.data}, () => resolve(response.data) );
            }).catch((err) => {
                log.error("getTutorials error:", err);
                reject(err);
            });
        });
    }

    loadBaseline() {
        const { idToken, patientId } = this.state;

        return new Promise((resolve, reject) => {
            let getBaselineJournal = {
                url: Config.baseUrl + `${Config.pathPrefix}journal/${patientId}?baseline=true&limit=1`,
                method: 'GET',
                headers: { "Authorization": idToken },
            };

            log.debug("loadBaseline:", getBaselineJournal);
            AxiosRequest(getBaselineJournal).then((response) => {
                log.debug("loadBaseline result:", response.data);
                let lastBaseline = response.data.records.length > 0 ? response.data.records[0] : null;
                this.setState({ lastBaseline }, () => resolve(lastBaseline));
            }).catch((err) => {
                console.error("loadBaseline error:", err);
                reject(err);
            })
        })
    }

    checkTutorial() {
        const { tutorials } = this.state;

        const activeTutorials = tutorials.filter((e) => {
            if ( localStorage.getItem(`tutorial-${e.educationId}-skip`)) {
                return false;
            }
            return true;
        })
        if ( activeTutorials.length > 0 ) {
            let tutorial = activeTutorials[0];
            this.setState({dialog: <TutorialDialog appContext={this} tutorial={tutorial}
                onClose={() => this.setState({dialog: null}, () => this.checkForcedCheckInReminder() )}
                onDismiss={() => {
                    localStorage.setItem(`tutorial-${tutorial.educationId}-skip`, 'true' );
                    this.setState({dialog: null}, () => this.checkForcedCheckInReminder() );
                }}
            />})
        } else {
            this.checkForcedCheckInReminder();
        }
    }

    checkForcedCheckInReminder() {
        const { org } = this.state;
        const forcedCheckInReminder = toBoolean(_.get(org, 'config.forcedCheckInReminder'), false);
        log.debug("checkForcedCheckinReminder:", forcedCheckInReminder );
        if ( forcedCheckInReminder ) {
            let { patient: { reminderEnabled, reminderTime, reminderInterval, phoneNumbers } } = this.state;
            //reminderEnabled = undefined;        // force on for testing
            log.debug(`reminderEnabled: ${reminderEnabled}, reminderTime: ${reminderTime}, reminderInterval: ${reminderInterval}`);
            if ( reminderEnabled === undefined || reminderEnabled === null ) {
                const validMobile = (phoneNumbers || []).find((e) => e.numberType === 'mobile' && Phone(e.number).isValid );
                log.debug(`validMobile: ${JSON.stringify(validMobile)}`);
                if ( validMobile ) {
                    // auto-enable the reminder for the current time and go to the next step
                    //console.log("Setting forcedCheckInReminderMessage to true.");
                    this.setState({ forcedCheckInReminderMessage: true }, () => {
                        //console.log("forcedCheckInReminderMessage:", this.state.forcedCheckInReminderMessage );
                    });
                    this.updatePatient({ 
                        reminderEnabled: true, 
                        reminderTime: Moment().add(1, 'week').toDate(),       // default to this current time
                        reminderInterval: (1440 * 7)                                // default to weekly
                    }).then(() => this.checkBaseline());
                } else {
                    // no valid phone number, pop up the dialog directly..
                    this.setState({dialog: <ReminderDialog appContext={this} onSet={() => {
                        this.setState({dialog: null}, () => this.checkBaseline());
                    } } />})
                }
            } else {
                this.checkBaseline();
            }
        } else {
            this.checkBaseline();
        }
    }

    checkBaseline() {
        const { org } = this.state;
        const { lastBaseline, patient, planActive } = this.state;

        const inConversation = window.location.pathname.endsWith('/conversation');          // we don't display the checkDaily if they are already checking in
        const orgConfig = _.get(org, "config") || {};
        const disableBaselineCheck = toBoolean(_.get(orgConfig, "disableBaselineCheck", "false"));
        const baselineCheckDelay = Number(_.get(orgConfig,"baselineCheckDelay", 0 ));
        const daysSinceAcceptedTerms = Moment().diff(Moment(patient.acceptedTerms),'days',true);

        log.debug("daysSinceAcceptedTerms:", daysSinceAcceptedTerms, patient.acceptedTerms, baselineCheckDelay);
        if (!inConversation && planActive && !disableBaselineCheck && daysSinceAcceptedTerms >= baselineCheckDelay && 
            (lastBaseline === null || !lastBaseline.journal.find((e) => e.eod === true))) {
            let dialog = <Dialog open={true} fullWidth={true} maxWidth={'xs'} style={{ background: 'rgba(0,0,0,0.6)' }}>
                <DialogContent id="baseline-dialog" >
                    <T.span text='checkBaseline' />
                </DialogContent>
                <DialogActions>
                    <Button id="baselineCancel" color="primary" variant="contained" 
                        onClick={this.closeDialog.bind(this)}><T.span text='cancel' /></Button>
                    <Button id="baselineYes" color="primary" variant="contained" component={Link} to='/baseline' 
                        onClick={this.closeDialog.bind(this)}><T.span text='yes' /></Button>
                </DialogActions>
            </Dialog>;

            this.setState({ dialog });
        }
        else {
            // found a baseline, check for a daily check-in now..
            this.checkDaily();
        }
    }

    checkDaily() {
        const { org, patient, planActive } = this.state;
        const { lastCheckInAlert } = this.state.patient;

        const inConversation = window.location.pathname.endsWith('/conversation');          // we don't display the checkDaily if they are already checking in
        const orgConfig = _.get(org, "config") || {};
        const disableDailyCheck = toBoolean(_.get(orgConfig, "disableDailyCheck", "false"));

        let checkInPrompt = !lastCheckInAlert;
        if (planActive && lastCheckInAlert && lastCheckInAlert.data) {
            let overallAlert = lastCheckInAlert.data[0];
            let endOfDay = Moment(lastCheckInAlert.eventTime).endOf('day').toDate();
            let checkInToday = Moment() < endOfDay;
            log.info(`checkInToday: ${checkInToday}, endOfDay: ${endOfDay.toISOString()}, overallAlert: ${overallAlert}`);
            // save the sessionId, if the last check-in was on the same day
            if (checkInToday) {
                this.setState({ lastSessionId: lastCheckInAlert.sessionId });
            } else {
                checkInPrompt = true;
            }
        }

        if (!inConversation && planActive && checkInPrompt && !disableDailyCheck && patient.inactive !== true) {
            let dialog = <Dialog open={true} fullWidth={true} maxWidth={'xs'} style={{ background: 'rgba(0,0,0,0.6)' }}>
                <DialogContent id="checkDaily-dialog" style={{ marginBottom: 0 }}>
                    <div style={{ marginRight: 10, float: 'left' }}><ScheduleRoundedIcon style={{ fontSize: '48' }} /></div>
                    <div style={{ marginTop: 5, textAlign: 'justify' }}><T.span text='checkDaily' /></div>
                </DialogContent>
                <DialogActions>
                    <Button id="checkDailyCancel" color="primary" variant="contained" onClick={this.closeDialog.bind(this)}><T.span text='no' /></Button>
                    <Button id="checkDailyYes" color="primary" variant="contained" component={Link} to='/conversation' onClick={() => {
                        log.metric({ EventName: 'PatientAction', Action: 'promptedCheckIn', count: 1 })
                        this.closeDialog();
                    }}><T.span text='yes' /></Button>
                </DialogActions>
            </Dialog>;
            this.setState({ dialog });
        }
    }

    onRejectedTerms() {
        log.debug("onRejectedTerms()");
        this.closeDialog();
        this.confirmSignOut();
    }

    onAcceptedTerms() {
        log.debug("onAcceptedTerms()");
        this.closeDialog();
        this.setState({ termsAccepted: true });
        this.checkTutorial();
        //this.checkBaseline()
    }

    onSignIn(login) {
        const { username, refreshToken, idToken, userId } = login;
        
        log.update({ idToken, userId });
        this.postMessage({ action: 'onRefreshToken', username, refreshToken });
        this.getSelf();
        this.initializeMenu();
    }

    initializeMenu() {
        if (!this.isCompactDisplay() && !this.state.menu)
            this.onToggleMenu();
    }

    onSignOut() {
        log.debug("onSignOut()", this);
        this.setState({
            dialog: <Dialog model="false" open={true} onClose={() => this.closeDialog()} fullWidth={true} maxWidth={'xs'} style={{ background: 'rgba(0,0,0,0.6)' }}>
                <DialogTitle><SignOutIcon style={{ float: 'left', marginRight: 5 }} /><T.span text="signOut" /></DialogTitle>
                <DialogContent id="areYouSureSignout" >
                    <DialogContentText><T.span text="areYouSureSignout" /></DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="signoutCancel" color="primary" variant="contained" style={styles.button} onClick={this.closeDialog.bind(this)} ><T.span text="cancel" /></Button>
                    <Button id="signoutConfirm" color="primary" variant="contained" style={styles.button} onClick={this.confirmSignOut.bind(this)}><T.span text="confirm" /></Button>
                </DialogActions>
            </Dialog>
        });
    }

    closeDialog() {
        log.debug("closeDialog");
        this.setState({ dialog: null });
    }

    confirmSignOut() {
        log.debug("confirmSignOut:", this);
        log.update({idToken: ''});                      // clear the idToken from the logger so we won't send metrics/logs anymore to the back-end
        
        clearInterval(this.intervalTimer);
        sessionStorage.removeItem('idToken');
        sessionStorage.removeItem('refreshToken');
        sessionStorage.removeItem('userId');

        this.setState({
            username: '', patientId: '', idToken: null, refreshToken: null, dialog: null,
            configLoaded: false, textLoaded: false, alertLevelsLoaded: false, providerLoaded: false, orgLoaded: false, idleDialog: null
        });
    }

    refreshMenu() {
        let { menu } = this.state;
        //only refresh if the menu is showing
        if (menu)
            this.setState({ menu: this.getMenu() });
    }

    onToggleMenu(e) {
        let { menu } = this.state;

        if (menu) {
            this.setState({ menu: null, leftNavOpen: false });
        }
        else {
            this.setState({ menu: this.getMenu(), leftNavOpen: !this.isCompactDisplay() })
        }
    }

    onCall(phoneNumbers) {
        if (isArrayValid(phoneNumbers)) {
            let dialog = <Dialog open={true} fullWidth={true} maxWidth={'xs'} style={{ background: 'rgba(0,0,0,0.6)' }}>
                <DialogTitle id="call-dialog-title" style={{ margin: 0, textAlign: 'left', background: '#808080', padding: 10 }}>
                    <T.span style={{ margin: 5, fontSize: 20, color: '#ffffff' }} text='callInstructions' />
                </DialogTitle>
                <List>
                    {phoneNumbers.map((phoneNumber) => {
                        const number = Phone(phoneNumber.number);
                        console.log("number:", number );
                        if (number.isValid) {
                            if (phoneNumber.name === undefined) phoneNumber.name = phoneNumber.numberType;
                            return <ListItem key={phoneNumber.name} style={{ textAlign: "left" }}>
                                <ListItemAvatar>
                                    <Avatar style={{ backgroundColor: '#eeeeee' }}>
                                        <CallIcon style={{ color: '#808080' }} />
                                    </Avatar>
                                </ListItemAvatar>
                                <Button id="phoneNumber" style={{ display: "block", textAlign: 'left' }} color="primary"
                                    onClick={() => {
                                        this.onURL({ href: `tel://${number.phoneNumber}`});
                                        this.setState({ openDialog: null })
                                    }}>{phoneNumber.name + ": " + number.phoneNumber}</Button>
                            </ListItem>
                        } else {
                            return null;
                        }
                    })}
                </List>
                <DialogActions>
                    <Button id="phoneCancel" variant="contained" color="primary" onClick={() => this.setState({ openDialog: null })}><T.span style={{ fontSize: 14 }} text='cancel' /></Button>
                </DialogActions>
            </Dialog>;
            this.setState({ openDialog: dialog });
        }
    }

    onURL(url) {
        log.debug("onURL:", url);
        let urlParts = new URL(url.href);
        log.debug("urlParts:", urlParts);

        function sameOrigin() {
            let protocol = urlParts.protocol.toLowerCase();
            if (protocol === 'mailto:' || protocol === 'tel:') {
                return true;
            }
            let host = urlParts.host;
            if (!host) {
                // If URI is not absolute or protocol-relative then it is always same-origin
                return true;
            }
            return window.location.host === host;
        }

        if (!sameOrigin()) {
            console.log("!sameOrigin()");
            let description = url.description ? T.texts[url.description] : '';
            if (isHTML(description))
                description = <div ref={this.hookDiv} dangerouslySetInnerHTML={{ __html: description }} />;
            else
                description = <div>{description}<br /><br /></div>;

            let openDialog = <Dialog open={true} fullWidth={true} maxWidth={'xs'} style={{ background: 'rgba(0,0,0,0.6)' }}>
                <DialogContent>{description}{url.disableWarning !== true ? <T.span text='urlWarning' /> : null}</DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.onCancelURL.bind(this)}><T.span text='cancel' /></Button>
                    <Button color="primary" onClick={this.onOpenURL.bind(this, url)}><T.span text='ok' /></Button>
                </DialogActions>
            </Dialog>;

            this.setState({ openDialog });
        }
        else {
            let protocol = urlParts.protocol.toLowerCase();
            console.log("protocol:", protocol );
            if (protocol === 'mailto:' || protocol === 'tel:') {
                this.onOpenURL(url);
            }
            else {
                // same origin, so just open the URL with a redirect..
                let pathname = urlParts.pathname;
                this.setState({ dialog: <Redirect to={pathname} /> }, () => this.setState({ dialog: null }));
            }
        }
    }

    onOpenURL(url) {
        if (isReactNative()) {
            this.postMessage({ action: 'onOpenURL', url });
        } else {
            window.open(url.href, "_blank");
        }
        this.onCancelURL();
    }

    onOpenFile(url) {
        if (isReactNative()) {
            this.postMessage({ action: 'onOpenURL', url });
        } else {
            window.open(url.href, "_blank");
        }
    }

    onCancelURL() {
        this.setState({ openDialog: null });
    }

    getMenu() {
        const { classes } = this.props;
        const { config, providerPhones, org, orgPhones, surveys, trials, messageCount, patient, planActive } = this.state;
        const newTrials = (trials || []).filter((e) => e.new);

        const logMetric = (metric) => () => {
            log.metric({ EventName: 'PatientAction', Action: metric, count: 1 })
        }
        const menu = _.get(config, "menu") || { "signOut": true };
        const orgConfig = _.get(org, "config") || {};
        const MENU_ITEMS = {
            "dashboard": <MenuItem id="dashboardMenu" key='dashboard' component={NavLink} to="/dashboard" activeClassName="Mui-selected" onClick={logMetric('home')}>
                <ListItemIcon className={classes.icon}><DashboardIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="dashboard" />} />
            </MenuItem>,
            "conversation": <MenuItem id="check-inMenu" metric='checkIn' key='conversation' disabled={isDisabled(menu, 'conversation') || !planActive || (patient && patient.inactive === true)} 
                onClick={logMetric('checkIn')}
                component={NavLink} to="/conversation" activeClassName="Mui-selected">
                <ListItemIcon className={classes.icon}><ConversationIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="conversation" />} />
            </MenuItem>,
            "surveys": <MenuItem id="surveysMenu" key='surveys' disabled={isDisabled(menu, 'surveys')} component={NavLink} to="/surveys" activeClassName="Mui-selected" onClick={logMetric('surveys')}>
                <ListItemIcon className={classes.icon}>
                    <Badge invisible={surveys.length === 0} color='secondary' overlap="circular" badgeContent={`${surveys.length}`}>
                        <SurveysIcon />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary={<T.span text="surveys" />} />
            </MenuItem>,
            "trials": <MenuItem id="trialsMenu" key='trials' disabled={isDisabled(menu, 'trials')} component={NavLink} to="/trials" activeClassName="Mui-selected" onClick={logMetric('trials')}>
                <ListItemIcon className={classes.icon}>
                    <Badge invisible={newTrials.length === 0} color='secondary' overlap="circular" 
                        badgeContent={`${newTrials.length}`}>
                        <TrialsIcon />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary={<T.span text="trials" />} />
            </MenuItem>,
            "vaccinations": <MenuItem id="vaccinationsMenu" key='vaccinations' disabled={isDisabled(menu, 'vaccinations')} component={NavLink} to="/vaccinations" activeClassName="Mui-selected" onClick={logMetric('vaccinations')}>
                <ListItemIcon className={classes.icon}><VaccinationIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="vaccinations" />} />
            </MenuItem>,
            "activity": <MenuItem id="activityMenu" key='activity' disabled={isDisabled(menu, 'activity')} component={NavLink} to="/activity" activeClassName="Mui-selected" onClick={logMetric("activity")}>
                <ListItemIcon className={classes.icon}><NetworkCheckIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="activity" />} />
            </MenuItem>,
            "test": <MenuItem key='testMenu' disabled={isDisabled(menu, 'test')} component={NavLink} to="/test" activeClassName="Mui-selected" onClick={logMetric('testResults')}>
                <ListItemIcon className={classes.icon}><AddBoxIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="test" />} />
            </MenuItem>,
            "resources": <MenuItem id="resourcesMenu" key='resources' disabled={isDisabled(menu, 'resources')} component={NavLink} to="/resources" activeClassName="Mui-selected" onClick={logMetric('resources')}>
                <ListItemIcon className={classes.icon}><SunIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="resources" />} />
            </MenuItem>,
            "clearance": <MenuItem id="quarantine-isolationMenu" key='clearance' disabled={isDisabled(menu, 'clearance')} component={NavLink} to="/clearance" activeClassName="Mui-selected" onClick={logMetric('clearance')}>
                <ListItemIcon className={classes.icon}><ClearanceIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="clearance" />} />
            </MenuItem>,
            "report": <MenuItem id="reportMenu" disabled={isDisabled(menu, 'report')} key='report' component={NavLink} to="/report" activeClassName="Mui-selected" onClick={logMetric('eReport')}>
                <ListItemIcon className={classes.icon}><ReportIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="report" />} />
            </MenuItem>,
            "profile": <MenuItem id="profileMenu" disabled={isDisabled(menu, 'profile')} key='profile' component={NavLink} to="/profile" activeClassName="Mui-selected" onClick={logMetric('profile')}>
                <ListItemIcon className={classes.icon}><ProfileIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="profile" />} />
            </MenuItem>,
            "messages": <MenuItem id="messagesMenu" disabled={isDisabled(menu, 'messages')} key='messages' component={NavLink} to="/messages" activeClassName="Mui-selected" onClick={logMetric('messages')}>
                <ListItemIcon className={classes.icon}>
                    <Badge invisible={messageCount === 0} color='secondary' overlap="circular" badgeContent={`${messageCount}`}>
                        <MessagesIcon />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary={<T.span text="messages" />} />
            </MenuItem>,
            "appointments": <MenuItem id="appointmentsMenu" disabled={isDisabled(menu, 'appointments')} key='appointments' component={NavLink} to="/appointments" activeClassName="Mui-selected" onClick={logMetric('appointments')}>
                <ListItemIcon className={classes.icon}><AppointmentsIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="appointments" />} />
            </MenuItem>,
            "education": <MenuItem id="educationMenu" disabled={isDisabled(menu, 'education')} key='education' component={NavLink} to="/education" activeClassName="Mui-selected" onClick={logMetric('education')}>
                <ListItemIcon className={classes.icon}><EducationIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="education" />} />
            </MenuItem>,
            "videoChat": <MenuItem id="videoChatMenu" disabled={isDisabled(menu, 'videoChat')} key='video' component={NavLink} to="/video" activeClassName="Mui-selected" onClick={logMetric('videoChat')}>
                <ListItemIcon className={classes.icon}><VideoIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="videoConf" />} />
            </MenuItem>,
            "callDr": <MenuItem id="callDrMenu" disabled={isDisabled(menu, 'callDr') || !isArrayValid(providerPhones)} key='callDr'
                onClick={ () => {
                    logMetric('callDr')();
                    this.onCall(providerPhones)
                }}>
                <ListItemIcon className={classes.icon}><CallIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="callDr" />} />
            </MenuItem>,
            "callOrg": <MenuItem id="callOrgMenu" disabled={isDisabled(menu, 'callOrg') || !isArrayValid(orgPhones)} key='callOrg'
                onClick={ () => {
                    logMetric('callOrg')();
                    this.onCall(orgPhones)}
                }>
                <ListItemIcon className={classes.icon}><CallIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="callOrg" />} />
            </MenuItem>,
            "information": <MenuItem id="informationMenu" disabled={isDisabled(menu, 'information') || this.state.faq.length === 0} key='faq' component={NavLink} to="/faq" activeClassName="Mui-selected" onClick={logMetric('information')}>
                <ListItemIcon className={classes.icon}><FAQIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="faq" />} />
            </MenuItem>,
            "companyPolicy": <MenuItem id="policyMenu" disabled={isDisabled(menu, 'companyPolicy') || this.state.companyPolicy.length === 0} key='companyPolicy' component={NavLink} onClick={logMetric('companyPolicy')}
                to="/companyPolicy" activeClassName="Mui-selected" >
                <ListItemIcon className={classes.icon}><FAQIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="companyPolicy" />} />
            </MenuItem>,
            "emlife": <MenuItem id="emlifeMenu" disabled={isDisabled(menu, 'emlife')} key='emlife' 
                onClick={() => {
                    logMetric('emlife')();
                    this.onURL(getSettings(menu, 'emlife'))
                }}>
                <ListItemIcon><WebIcon /></ListItemIcon>
                <ListItemText primary={<T.span text={_.get(menu, "emlife.title")} />} />
            </MenuItem>,
            "feedback": <MenuItem id="feedbackMenu" disabled={isDisabled(menu, 'feedback')} key='feedback' component={NavLink} to="/feedback" activeClassName="Mui-selected" onClick={logMetric('feedback')}>
                <ListItemIcon className={classes.icon}><FeedbackIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="feedback" />} />
            </MenuItem>,
            "settings": <MenuItem id="settingsMenu" key='settings' component={NavLink} to="/settings" activeClassName="Mui-selected" onClick={logMetric('settings')}>
                <ListItemIcon className={classes.icon}><SettingsIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="settings" />} />
            </MenuItem>,
            "signOut": <MenuItem id="signoutMenu" key='signout' onClick={(e) => { logMetric('signout'); this.onSignOut(); }}>
                <ListItemIcon className={classes.icon}><SignOutIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="signOut" />} />
            </MenuItem>
        };

        let menuItems = [];
        for (let k in menu) {
            if (isVisible(this, menu, orgConfig, k)) menuItems.push(MENU_ITEMS[k]);
        }

        let logoSrc = this.getLogo();
        let logoAlt = org ? org.name + " Logo" || "Apricity Logo" : "Apricity Logo";

        let menuItem = <ClickAwayListener onClickAway={() => {
            if (this.isCompactDisplay())
                this.onToggleMenu();
        }}>
            <Drawer classes={{ paper: classes.drawerPaper }} variant="persistent" open={true}>
                <div align="left">
                    <div style={styles.sidebarHeader}>
                        <img style={styles.logo} src={logoSrc} alt={logoAlt} />
                    </div>
                    <MenuList style={{ paddingBottom: '60px' }}>
                        {menuItems}
                    </MenuList>
                    <div style={styles.sidebarFooter}>
                        <T.span text='menuFooter' />
                    </div>
                </div>
            </Drawer>
        </ClickAwayListener>;

        return menuItem;
    }

    onRegisterDone(login) {
        const { username, userId, idToken, refreshToken } = login;
        log.debug("onRegisterDone:", login);
        sessionStorage.setItem('username', username);
        sessionStorage.setItem('userId', userId);
        sessionStorage.setItem('idToken', idToken);
        sessionStorage.setItem('refreshToken', refreshToken);
        this.postMessage({ action: 'onRefreshToken', username, refreshToken });

        if (login.country !== 'US') {
            this.onSetTemperatureUnit('C');     // default to C for everyone except the US
        }

        log.update({ idToken, userId });
        this.setState({
            dialog: null,
            username,
            idToken,
            refreshToken,
            userId,
            registerDone: true
        });
        this.getSelf();
        this.startLoginRefresh();
        this.initializeMenu();
    }

    onRegister(opts) {
        let { domainOrg, defaultPlanId, defaultProviderId } = this.state;
        let query = getQuery();
        let providerId = query.get('providerId') || defaultProviderId;
        let planId = query.get('planId') || defaultPlanId || Config.defaultPlanId;
        let registrationCode = query.get('registrationCode') || undefined;

        log.debug(`onRegister providerId: ${providerId}, planId: ${planId}`);
        this.setState({
            dialog: <CreateAccountDialog appContext={this} planId={planId} providerId={providerId} org={domainOrg} registrationCode={registrationCode}
                opts={opts} onCancel={this.closeDialog.bind(this)} onDone={this.onRegisterDone.bind(this)} />
        });
    }

    getLogo() {
        const logoOverride = localStorage.getItem('logoOverride');
        const defaultLogo = getAppName(); 
        const logo = logoOverride || defaultLogo;

        switch(logo) {
            case 'Apricity@Work':
                return ApricityWorkLogo;
            case 'ApricityCare':
                return ApricityCareLogo;
            default:
                return logo;
        }
    }

    getResetPassword(username) {
        if (this._resetPassword !== true) {
            this._resetPassword = true;
            const resetPassword = {
                url: Config.baseUrl + `${Config.pathPrefix}anon/authentication/resetPassword`,
                method: 'POST',
                data: {
                    username
                }
            }
            console.log("resetPassword request:", resetPassword);
            AxiosRequest(resetPassword).then((result) => {
                console.log("resetPassword result:", result.data );
                this.setState({ resetPassword: true });
            }).catch((err) => {
                this.setState({ resetPasswordError: getErrorMessage(err) });
                console.error("resetPassword error:", err );
            })
        }

        if (this.state.resetPassword === true) {
            return <div className='App-loading'><T.span text='passwordReset' /></div>;
        } else if ( this.state.resetPasswordError ) {
            return <div className='App-loading'><span style={{ color: 'red' }}>{this.state.resetPasswordError}</span></div>
        } else {
            return <div className="App-loading"><CircularProgress /></div>;
        }
    }

    getValidateEmail(email, validationCode) {
        if (this._validatingEmail !== true) {
            this._validatingEmail = true;
            const validateEmail = {
                url: Config.baseUrl + `${Config.pathPrefix}anon/authentication/emailValidate`,
                method: 'POST',
                data: {
                    email,
                    validationCode
                }
            }
            console.log("emailValidate request:", validateEmail);
            AxiosRequest(validateEmail).then((result) => {
                console.log("emailValidate result:", result.data);
                this.setState({ emailValidated: true });
            }).catch((err) => {
                this.setState({ validateError: getErrorMessage(err) })
                console.error(err);
            })
        }

        if (this.state.emailValidated === true) {
            return <div className='App-loading'><T.span text='emailValidated' /></div>;
        } else if (this.state.validateError) {
            return <div className='App-loading'><span style={{ color: 'red' }}>{this.state.validateError}</span></div>
        } else {
            return <div className="App-loading"><CircularProgress /></div>;
        }
    }

    getLoginView() {
        if (this.state.domainDetected === false) {
            return <div className="App-loading"><CircularProgress /></div>;
        }
        const { dialog, progress, domainOrg, androidLink, iosLink, removeAccount } = this.state;
        const logo = this.getLogo();
        const query = getQuery();
        const email = query.get('email');
        const resetPassword = query.get('resetPassword');
        const validationCode = query.get('validationCode');
        if (email && validationCode) { 
            return this.getValidateEmail(email, validationCode);
        } else if ( resetPassword ) {
            return this.getResetPassword(resetPassword);
        }
        const disableRegister = toBoolean(query.get("disableRegister"))
            || toBoolean(_.get(domainOrg, 'config.disableRegister'));

        const register = removeAccount ? <div align='left' style={{ margin: 15}} ><b><T.span text='removeAccountInstructions' /></b></div> :
            (domainOrg || query.get('providerId')) && !disableRegister ? <div><Button id="register" variant="outlined" style={styles.registerButton}
            onClick={this.onRegister.bind(this)}><T.span text="register" /></Button></div> : null;

        const nativeInstallLinks = !removeAccount && !this.state.isReactNative && (iosLink || androidLink) ?
            <div>
                {androidLink ? <Button onClick={() => window.open(androidLink.url)}><img alt='Android' style={{ width: 150 }} src={AndroidStoreImage} /></Button> : null}
                {iosLink ? <Button onClick={() => window.open(iosLink.url)} ><img alt='iOS' style={{ width: 150 }} src={iOSStoreImage} /></Button> : null}
                <br /><br />
            </div> : null;

        const providedBy = domainOrg ? domainOrg.name : undefined;

        return <div>
        <LoginView
                providedBy={providedBy}
                logo={logo}
                appContext={this}
                group={'patients'}
                onDone={this.onSignIn.bind(this)}
                onSignOut={this.confirmSignOut.bind(this)}
                skipAccount={true}
                register={register}
                onRegisterRequired={this.onRegister.bind(this)}
                enableFederatedLogin={true}
                appButtonLinks={nativeInstallLinks} />
            {dialog}
            {progress}
        </div>
    }

    getRemoveAccountView() {
        const { removeAccountConfirmation } = this.state;

        return <Dialog open={true}>
            <DialogTitle><T.span text='removeAccountRequest' /></DialogTitle>
            <DialogContent>
                <T.span text='removeAccountDetails' /><br /><br />
                <div align='center'>
                <TextField label={T.translate('removeAccountConfirmation')} style={{margin: 5, width: 400}} value={removeAccountConfirmation} onChange={(e) => this.setState({ removeAccountConfirmation: e.target.value })} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={() => this.setState({ removeAccount: false})}><T.span text='cancel' /></Button>
                <Button variant='contained' disabled={removeAccountConfirmation.toLowerCase().trim() !== 'remove account'} onClick={this.removeAccountConfirmed.bind(this)}><T.span text='removeAccountConfirm' /></Button>
            </DialogActions>
        </Dialog>
    }

    removeAccountConfirmed() {
    }

    getView(view) {
        if (!this.state.idToken) {
            return this.getLoginView();
        }
        // NOTE: Rick didn't want the user to get channeled directly into a dialog to remove their account, so we just display instructions on the login page and that is all.
        // if (this.state.removeAccount) {
        //     return this.getRemoveAccountView();
        // }

        const { classes } = this.props;
        const { maintenance, dialog, openDialog, progress, termsAccepted, configLoaded, textLoaded, alertLevelsLoaded, menu } = this.state;

        const contentStyle = Object.assign({}, styles.content);
        if (this.state.leftNavOpen)
            contentStyle.marginLeft = 256;
        if (!termsAccepted || !configLoaded || !textLoaded || !alertLevelsLoaded || !this.state.patientId) {
            //log.debug(`termsAccepted: ${termsAccepted}, configLoaded: ${configLoaded}, textLoaded: ${textLoaded}, alertLevelsLoaded: ${alertLevelsLoaded}, patientId: ${this.state.patientId}`);
            view = <div align='center' style={styles.centeredDiv}><CircularProgress /></div>;
        }

        let homeIcon = <IconButton component={Link} to={'/dashboard'} style={{ color: '#666666', paddingTop: 0 }}><HomeIcon /></IconButton>;
        let navigationMenu = this.isCompactDisplay() ? <IconButton onClick={this.onToggleMenu.bind(this)} style={{ color: '#666666', paddingTop: 0 }}><NavigationMenu />{menu}</IconButton> :
            <div><IconButton onClick={this.onToggleMenu.bind(this)} style={{ color: '#666666', paddingTop: 0 }}><NavigationMenu /></IconButton>{menu}</div>;
        let pwaPrompt = isReactNative() ? null : <PWAPrompt promptOnVisit={1} timesToShow={4000} copyClosePrompt="Close" permanentlyHideOnDismiss={true} />;
        const activeMaintenance = maintenance ? maintenance.current || maintenance.upcoming : null;
        let maintenanceMessage = null;
        if (activeMaintenance) {
            maintenanceMessage = <Paper style={{ marginLeft: 25, marginRight: 25, marginTop: 15, marginBottom: 5, textAlign: 'left', background: '#ffc800', padding: 5 }}>
                <AnnouncementIcon style={{ fontSize: 20, float: 'left', padding: 5 }} />
                <Maintenance maintenance={activeMaintenance} />
            </Paper>;
        }

        return <div style={contentStyle}>
            <header>
                <AppBar style={{ height: 50, margin: 0, backgroundColor: '#ffffff' }} position="fixed">
                    <Toolbar>
                        {navigationMenu}
                        <Typography variant="h6" color="inherit" style={{ marginTop: -10, paddingTop: 0 }} className={classes.flex}><span style={{ color: '#666666' }}>{getAppName()}</span></Typography>
                        <div align='right'>
                            {homeIcon}
                        </div>
                    </Toolbar>
                </AppBar>
            </header>
            {pwaPrompt}
            {view}
            {maintenanceMessage}
            {dialog}
            {openDialog}
            {progress}
        </div>;
    }

    isSelected(path) {
        let pathname = window.location.pathname.slice(1).split('/');
        pathname = '/' + pathname[0]
        return (path === pathname);
    }

    hookDiv(ref) {
        if (ref) {
            ref.addEventListener('click', (e) => {
                if (e.target.tagName === 'A') {
                    this.onURL({ href: e.target.href });
                    e.preventDefault();
                }
            });
        }
    }

    createAppointment(obj) {
        if (!obj) return;

        const { idToken, org, patientId } = this.state;

        const data = {
            providerId: obj.providerId ? obj.providerId : obj.availableProviderIds && obj.availableProviderIds.length > 0 ? obj.availableProviderIds[0] : org.defaultProviderId,
            patientId,
            start: obj.start,
            end: obj.end,
            originator: 'patient',
            category: 'phone',
            status: 'confirmed'         // IOA-15770: create the appointment as already confirmed, the default is requested.
        };

        console.log('CreateAppointment', data, obj);

        return new Promise((resolve, reject) => {
            const request = {
                url: Config.baseUrl + `${Config.pathPrefix}orgs/${org.orgId}/appointments/create`,
                method: 'PUT',
                headers: { "Authorization": idToken },
                data
            };
            log.debug("createAppointment request", request);
            AxiosRequest(request).then((response) => {
                log.debug("createAppointment response:", response);
                resolve(response.data.user)
            }).catch((error) => {
                log.debug("createAppointment error:", error.response);
                reject(error)
            });
        });
    }

    onConversationDone(res) {
        log.debug("onConversationDone:", res);
        if ( res ) {
            const { patientId } = this.state;
    
            addActivity(this, { activityType: 'PatientCheckinCompleted', patientId, description: 'Patient completed check-in.' });
            this.setState({
                dialog: <EodDialog appContext={this} eodResult={res} onClose={() => this.setState({ dialog: null })} />,
                cache: {},
                hardReset: undefined
            });
        } else {
            // if no result, then an error has occured and the covnersation view is ending the conversation..
            this.setState({ dialog: <Redirect to='/' /> }, () => this.setState({ dialog: null }));
        }
    }

    onConversationCancel() {
        this.setState({dialog: <Dialog open={true}>
                <DialogTitle><T.span text='cancelCheckIn' /></DialogTitle>
                <DialogContent><T.span text='confirmCancelCheckIn' /></DialogContent>
                <DialogActions>
                    <Button id='cancel' onClick={() => this.setState({dialog: null})}><T.span text='cancel' /></Button>
                    <Button id='yes' onClick={() => {
                        log.metric({ EventName: 'PatientAction', Action: 'cancelledCheckIn', count: 1 })
                        this.setState({ dialog: <Redirect to='/' /> }, () => this.setState({ dialog: null }));
                    }}><T.span text='yes' /></Button>
                </DialogActions>
            </Dialog>})
    }

    onBaselineDone(completed) {
        const { patientId } = this.state;
        log.debug("onBaselineDone:", completed);

        this.loadBaseline();
        if (completed) {
            addActivity(this, { activityType: 'PatientBaselineCompleted', patientId, description: 'Patient completed baseline.' });
        }
    }

    onActive() {
        log.debug("onActive");
    }

    onIdle() {
        log.debug("onIdle");
        if (this.state.idToken)
            this.setState({ idleDialog: <IdleDialog appContext={this} /> })
    }

    // reload our patient record from the back-end and update our patient state with any changes... 
    reloadPatient() {
        const { idToken, patient, patientId } = this.state;

        const getPatient = {
            url: Config.baseUrl + `${Config.pathPrefix}patients/${patientId}`,
            method: 'GET',
            headers: { "Authorization": idToken }
        };

        log.info("reloadPatient request:", getPatient );
        return AxiosRequest(getPatient).then((result) => {
            log.info("reloadPatient result:", result.data );
            const updates = result.data.patient;
            for(let k in updates) {
                patient[k] = updates[k]
            }
        }).catch((err) => {
            log.error("getPatient error:", err );
        })

    }

    updatePatient(updates) {
        const { patient, patientId, idToken } = this.state;
        const sessionIdToken =  sessionStorage.getItem('idToken');
        if (! patientId ) {
            throw new Error("no valid patientId");
        }
        if (! idToken && !sessionIdToken ) {
            throw new Error("no valid idToken");
        }

        let updatePatient = {
            url: Config.baseUrl + `${Config.pathPrefix}patients/${patientId}`,
            method: 'PUT',
            headers: { Authorization: idToken || sessionIdToken },
            data: updates
        };

        // also apply the updates to our local patient record, please note the patient
        // can be null when applying a subscriptionId for example.
        if ( patient ) {
            for(let k in updates) {
                if ( updates[k] !== undefined ) {
                    patient[k] = updates[k];
                }
            }
        }

        console.log('updatePatient request:', updatePatient);
        return AxiosRequest(updatePatient).then((result) => {
            console.log("updatePatient result:", result.data );
            return result.data.patient;
        }).catch((err) => {
            console.error('updatePatient error:', err);
            throw err;
        })
    }

    getSubscriptionView() {
        const { subscriptionId, patientId, subscriptionError } = this.state;
        const idToken = sessionStorage.getItem('idToken');

        if (!patientId || !idToken || subscriptionError) {
            return <div className="App-loading"><T.span style={{ color: 'red' }} text='subscriptionError' /></div>
        }

        if (this._subscriptionView !== true) {
            this._subscriptionView = true;

            this.updatePatient({ subscriptionId }).then((result) => {
                const { manageUrl } = result.data.patient.subscriptionInfo;
                console.log('Sending onSubscibed message:', window.parent);
                this.postParentMessage({ action: 'reloadPatient' });

                console.log("Redirecting to URL:", manageUrl);
                window.location = manageUrl;

            }).catch((err) => {
                console.error('getSubscriptionView error:', err);
                this.setState({ subscriptionError: true });
            });
        }

        return <div className="App-loading"><CircularProgress /></div>;
    }

    getSnackbarMessages() {
        if ( !this.state.idToken || this.state.termsAccepted !== true || this.state.dialog !== null ) {
            // don't display the snackbar until the terms have been accepted and that no other dialog is showing
            return null;
        }
        let messages = [];

        const buttonStyle = { color: 'white' }; 
        const { patient, hideSubscribeSnackbar, hideReminderSnackbar, hideSurveySnackbar, forcedCheckInReminderMessage, surveys, org = {} } = this.state;
        if (patient && patient.inactive === true && !hideSubscribeSnackbar && (org && org.billingType === 'square') &&
            !toBoolean(_.get(org, 'config.disableSubscribeReminder'), false)) {
            messages.push(<div key='patientSubscribe'>
                <T.span text='patientSubscribe' />
                <Button onClick={() => this.setState({ hideSubscribeSnackbar: true })} style={buttonStyle}><T.span text='dismiss' /></Button>
                <Button onClick={() => this.setState({
                    hideSubscribeSnackbar: true,
                    dialog: <SubscriptionDialog appContext={this} onClose={() => this.setState({ dialog: null })} />
                })}
                    style={buttonStyle}><T.span text='subscribe' /></Button>
            </div>
            );
        }

        const disableCheckInReminder = toBoolean(_.get(org, 'config.disableCheckInReminder'), true);
        const forcedCheckInReminder = toBoolean(_.get(org, 'config.forcedCheckInReminder'), false);
        if (messages.length < 1 && patient && patient.inactive !== true && (patient.reminderEnabled === undefined || patient.reminderEnabled === null) && !hideReminderSnackbar
            && !disableCheckInReminder && !forcedCheckInReminder)  // check the org config, default to this being disabled, unless disableCheckInReminder is really false
        {
            messages.push(<div key='checkinReminder'>
                <T.span text='setupCheckInReminder' />
                <Button onClick={() => {
                    patient.reminderEnabled = false;
                    this.updatePatient({ reminderEnabled: false });
                    this.setState({ hideReminderSnackbar: true })
                }} style={buttonStyle}><T.span text='dismiss' /></Button>
                <Button onClick={() => this.setState({ hideReminderSnackbar: true, dialog: <ReminderDialog appContext={this} onSet={() => this.setState({dialog: null})} />}) } 
                    style={buttonStyle} ><T.span text='setup' /></Button>
            </div>
            )
        }

        if (messages.length < 1 && forcedCheckInReminderMessage ) {
            messages.push(<div key='forcedCheckInReminder'>
                <T.span text='forcedCheckInReminderActivated' />
                <Button onClick={() => this.setState({ forcedCheckInReminderMessage: false })} style={buttonStyle}><T.span text='dismiss' /></Button>
                <Button component={Link} to='/profile' onClick={() => this.setState({ forcedCheckInReminderMessage: false })} style={buttonStyle} ><T.span text='profile' /></Button>
            </div>);
        }

        //log.debug("surveys:", surveys );
        if (messages.length < 1 && Array.isArray(surveys) && surveys.length > 0 && !hideSurveySnackbar) {
            messages.push(<div key='surveys'>
                <T.span text='surveysAvailable' />
                <Button onClick={() => this.setState({ hideSurveySnackbar: true })} style={buttonStyle}><T.span text='dismiss' /></Button>
                <Button component={Link} to='/surveys' onClick={() => this.setState({ hideSurveySnackbar: true })} style={buttonStyle} ><T.span text='takeSurvey' /></Button>
            </div>
            )
        }

        //log.debug("snackbarMessages:", messages );
        if ( messages.length > 0 ) {
            return <Snackbar id='snackbar' open={true} style={{width: '100%', margin: 5}} autoHideDuration={null}>
                <SnackbarContent message={messages} />
            </Snackbar>;
        }
        return null;
    }

    render() {
        // if we have a subscriptionId in our state, then we need to handle associating a patient with their subscription ID
        if (this.state.subscriptionId) {
            return this.getSubscriptionView();
        }

        const theme = getTheme(this.state.theme);
        const { patientId, lastBaseline, timezone, config, language, gender, temperatureUnit, idleDialog, hardReset, patient, planActive } = this.state;

        //document.body.style = 'background: #eeeeee;';
        T.setTexts(this.appTexts[language]);
        return <div className="App">
            <IdleTimer onActive={this.onActive.bind(this)} onIdle={this.onIdle.bind(this)} debounce={250} timeout={IDLE_TIMEOUT_MS}>
                <ThemeProvider theme={theme}>
                    <ScrollToTop />
                    <Switch basename="/" >
                        <Route path="/web">
                            {this.getView(<WebView id="webView" appContext={this} />)}
                        </Route>
                        <Route path="/dashboard">
                            {this.getView(<DashboardView id="dashboardView" appContext={this} settings={config.dashboard || {}} />)}
                            {this.getSnackbarMessages()}
                        </Route>
                        <Route path="/resources">
                            {this.getView(<DashboardView id="resourcesView" appContext={this} settings={config.resources || {}}
                                icon={<SunIcon />} color='#ff9604'
                                label={<T.span text='resources' />} />)}
                        </Route>
                        <Route path="/vaccinations">
                            {this.getView(<VaccinationsView id="vaccinationsView" appContext={this} settings={config.vaccinations || {}}
                                icon={<VaccinationIcon />} color='#ff9604'
                                label={<T.span text='vaccinations' />} />)}
                        </Route>
                        <Route path="/test">
                            {this.getView(<CovidTestsView id="covidTestsView" appContext={this} settings={config.tests || {}}
                                icon={<AddBoxIcon />} color='#ff9604'
                                label={<T.span text='test' />} />)}
                        </Route>
                        <Route path="/report">
                            {this.getView(<ReportView id="reportView" appContext={this}
                                icon={<ReportIcon />} color='#ff9604'
                                label={<T.span text="report" />} />)}
                        </Route>
                        <Route path="/appointments">
                            {this.getView(<AppointmentsView id="appointmentsView" appContext={this}
                                icon={<AppointmentsIcon />} color='#0076bb'
                                label={<T.span text="appointments" />} />)}
                        </Route>
                        <Route path="/clearance">
                            {this.getView(<ClearanceView id="quarantine-isolationView" appContext={this} clearanceDays={config.clearanceDays || 14}
                                icon={<ClearanceIcon />} color='#686868'
                                label={<T.span text="clearance" />} />)}
                        </Route>
                        <Route path="/messages">
                            {this.getView(<MessagesView id="messagesView" appContext={this}
                                icon={<MessagesIcon />} color='#02365c'
                                label={<T.span text="messages" />} />)}
                        </Route>
                        <Route path="/video">
                            {this.getView(<VideoChatView id="videoView" appContext={this}
                                icon={<VideoIcon />} color='#402361'
                                label={<T.span text="videoConf" />} />)}
                        </Route>
                        <Route path="/faq">
                            {this.getView(<ContentView id="faqView" appContext={this} content={this.state.faq}
                                icon={<FAQIcon />} color='#0076bb'
                                label={<T.span text="faq" />} />)}
                        </Route>
                        <Route path="/companyPolicy">
                            {this.getView(<ContentView id="policyView" appContext={this} content={this.state.companyPolicy}
                                icon={<FAQIcon />} color='#0076bb'
                                label={<T.span text="companyPolicy" />} />)}
                        </Route>
                        <Route path="/feedback">
                            {this.getView(<FeedbackView id="feedbackView" appContext={this}
                                icon={<FeedbackIcon />} color='#0076bb'
                                label={<T.span text="feedback" />} />)}
                        </Route>
                        <Route path="/hotspots">
                            {this.getView(<HotSpotsView id="hotspotsView" appContext={this}
                                icon={<PinDropRoundedIcon />} color='#d61818'
                                label={<T.span text="hotSpots" />}
                                info={'hotSpotsDescription'} />)}
                        </Route>
                        <Route path="/education">
                            {this.getView(<EducationView id="educationView" appContext={this}
                                icon={<EducationIcon />} color='#d61818'
                                label={<T.span text="education" />} />)}
                        </Route>
                        <Route path="/baseline" render={(routeProps) => (
                            this.getView(<BaselineView
                                id="baselineView"
                                disabled={!planActive}
                                appContext={this}
                                patientId={patientId}
                                language={language}
                                timezone={timezone}
                                temperatureUnit={temperatureUnit}
                                lastBaseline={lastBaseline}
                                history={routeProps.history}
                                onDone={this.onBaselineDone.bind(this)}
                            />)
                        )}>
                        </Route>
                        <Route path="/surveys" render={(routeProps) => (
                            this.getView(<SurveyView
                                id="surveyView"
                                appContext={this}
                                patientId={patientId}
                                language={language}
                                timezone={timezone}
                                temperatureUnit={temperatureUnit}
                                history={routeProps.history}
                                onDone={this.getPatientSurveys.bind(this)}
                            />)
                        )}>
                        </Route>
                        <Route path="/trials" render={(routeProps) => (
                            this.getView(<TrialsView
                                id="trialsView"
                                appContext={this}
                                patientId={patientId}
                                language={language}
                                timezone={timezone}
                                onDone={this.getPatientTrials.bind(this)}
                            />)
                        )}>
                        </Route>
                        <Route path="/conversation">
                            {this.getView(<div>
                                <ConversationView id="conversationView" appContext={this}
                                    disabled={!patient || patient.inactive || !planActive}
                                    source={[{ client: isReactNative() ? 'patient-mobile-client' : 'patient-web-client' }]}
                                    hardReset={hardReset}
                                    patientId={patientId}
                                    language={language}
                                    gender={gender}
                                    temperatureUnit={temperatureUnit}
                                    ref={(conversationView) => window.conversationView = conversationView}
                                    symptomTracking={config.symptomTracking}
                                    timezone={timezone}
                                    onDone={this.onConversationDone.bind(this)}
                                    onCancel={this.onConversationCancel.bind(this)}
                                    icon={<CheckInIcon />} color='#51cc42'
                                    label={<T.span text="conversation" />}
                                    disclaimer={'disclaimer'} /></div>)}
                        </Route>
                        <Route path="/settings">
                            {this.getView(<SettingsView id="settingsView" appContext={this}
                                icon={<SettingsIcon />} color='#0076bb'
                                label={<T.span text="settings" />} />)}
                        </Route>
                        <Route path="/profile" render={(routeProps) => (
                            this.getView(<ProfileView id="profileView" appContext={this}
                                {...routeProps}
                                onMount={this.loadBaseline.bind(this)}
                                icon={<ProfileIcon />} color='#0076bb'
                                label={<T.span text="profile" />} />)
                        )}>
                        </Route>
                        <Route path="/">
                            {this.getView(<DashboardView appContext={this} settings={config.dashboard} />)}
                            {this.getSnackbarMessages()}
                        </Route>
                    </Switch>
                    {idleDialog}
                </ThemeProvider>
            </IdleTimer>
        </div>;
    }
}


export function saveUser(idToken, user) {
    return new Promise((resolve, reject) => {
        const request = {
            url: Config.baseUrl + `${Config.pathPrefix}authentication/users/`,
            data: user,
            method: 'POST',
            headers: { "Authorization": idToken }
        };
        log.debug("saveUser request", request);
        AxiosRequest(request).then((response) => {
            log.debug("saveUser response:", response.data);
            resolve(response.data.user)
        }).catch((error) => {
            log.debug("saveUser error:", error.response);
            resolve({})
        });
    });
}

const styles = {
    footer: {
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        paddingLeft: 10,
        paddingBottom: 10,
        textAlign: 'center'
    },
    registerButton: {
        backgroundColor: '#ffffff',
        borderColor: 'green',
        margin: 15,
        width: 250
    },
    button: {
        margin: 10
    },
    call: {
        margin: 16,
        color: '#000000'
    },
    div: {
    },
    content: {
        transition: 'margin-left 450ms cubic-bezier(0.23, 1, 0.32, 1)'
    },
    flex: {
        flex: 1,
    },
    drawerPaper: {
        width: 256,
    },
    logo: {
        width: '90%',
        maxWidth: '300px',
    },
    sidebarHeader: {
        padding: '10px 10px 0 10px',
        textAlign: 'center'
    },
    sidebarFooter: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '251px',
        fontStyle: 'italic',
        fontSize: 12,
        paddingLeft: 5,
        paddingBottom: 5,
        color: '#666666',
        backgroundColor: 'white'
    },
    innerConversation: {
        padding: 10
    },
    eodDisclaimer: {
        marginBottom: 10,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    centeredDiv: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    }
};


App.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
