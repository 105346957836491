import React from 'react';
import {
    Grid,
    Paper,
    Button,
    Avatar,
} from '@material-ui/core/';

import { Redirect } from 'react-router-dom';
import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
//import { TelnyxVideoChat } from '@apricityhealth/web-common-lib/components/TelnyxVideoChat';
import { TelnyxVideoChat } from './TelnyxVideoChat';
import { Logger } from '@apricityhealth/web-common-lib'

import Config from '@apricityhealth/web-common-lib/Config';

import T from 'i18n-react';
import Moment from 'moment';

import AppointmentsIcon from '@material-ui/icons/Today';

const log = new Logger();

export class VideoChatView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            getContent: false,
            appointmentIn: null,
            appointmentTime: null,
            providerName: null,
            canVideoChat: false,
            unconfirmedAppointments: [],
            nextAppointmentIsUnconfirmed: false
        };
    }

    componentDidMount() {
        this.getContent();
    }

    componentWillUnmount() {
    }

    getContent() {
        Promise.all([
            this.getAppointments(),
            this.getPatientProviders(),
        ]).then(([appointments, providers]) => {
            // look for next or active appointment
            let nextAppointment = null;         // our next appointment

            // get unconfirmed
            let unconfirmedAppointments = []

            if (Array.isArray(appointments)) {
                for (let i = 0; i < appointments.length; ++i) {
                    let appt = appointments[i];
                    if (Moment(appt.end) > Moment() && appt.status === 'requested') {
                        unconfirmedAppointments.push(appt)
                    }
                    if (!nextAppointment || Moment(appt.start) < Moment(nextAppointment.start))
                        nextAppointment = appt;
                }
            }
            this.setState({ unconfirmedAppointments })

            log.debug("nextAppointment:", nextAppointment);
            let canVideoChat = false;
            if (nextAppointment) {
                let providerRecord = providers.find((e) => e.provider.providerId === nextAppointment.providerId);
                let providerName = nextAppointment.providerId;
                if (providerRecord && providerRecord.provider) {
                    let provider = providerRecord.provider;
                    providerName = `${provider.firstName} ${provider.lastName}`;
                    if (provider.title)
                        providerName = `${provider.title} ` + providerName;
                }

                let now = Moment();                                             // 1585176285664
                let days = Moment(nextAppointment.start).diff(now, 'days');
                let hours = Moment(nextAppointment.start).diff(now, 'hours');
                let minutes = Moment(nextAppointment.start).diff(now, 'minutes');
                if (hours > 0)
                    minutes = minutes % 60;

                let apptUpcoming = Moment(nextAppointment.start).isAfter(now);
                let apptNow = now.isAfter(Moment(nextAppointment.start)) && now.isBefore(nextAppointment.end); //now >= Moment(nextAppointment.start);
                let apptDone = Moment(nextAppointment.end).isBefore(now);

                canVideoChat = nextAppointment.status === 'confirmed' && apptUpcoming && !apptDone;
                log.debug(`canVideoChat: ${canVideoChat}, apptNow: ${apptNow}, apptUpcoming: ${apptUpcoming}, apptDone: ${apptDone}`);

                let appointmentIn = days > 0 ? <T.span text={{ key: 'days', days }} /> :
                    hours > 0 ? <T.span text={{ key: 'hours', hours, minutes }} /> :
                        <T.span text={{ key: 'minutes', minutes }} />;
                let appointmentTime = Moment(nextAppointment.start).local().format('MMMM Do YYYY, h:mm a');

                let nextAppointmentIsUnconfirmed = nextAppointment.status === 'requested'
                this.setState({
                    getContent: true,
                    appointment: nextAppointment,
                    apptNow, 
                    apptDone,
                    appointmentIn, 
                    appointmentTime,
                    providerName,
                    nextAppointmentIsUnconfirmed
                });
            }
            else {
                this.setState({ getContent: true })
            }
        })
    }

    getPatientProviders() {
        const { appContext } = this.props
        const { patientId, idToken } = appContext.state;

        return new Promise((resolve, reject) => {
            const getPatientProviders = {
                url: Config.baseUrl + `${Config.pathPrefix}providers/patient/${patientId}?includeOrg=true&inactive=false`,
                method: 'GET',
                headers: { "Authorization": idToken }
            };

            log.debug("getPatientProviders:", getPatientProviders);
            AxiosRequest(getPatientProviders).then((result) => {
                let providers = result.data.providers;
                log.debug("getPatientProviders result", providers);
                resolve(providers);
            }).catch((err) => {
                log.debug("getPatientProviders error:", err);
                reject(err);
            })
        });
    }

    getAppointments() {
        const { appContext } = this.props;
        const { patientId, idToken } = appContext.state;

        return new Promise((resolve, reject) => {
            let currentTime = Moment().toISOString()
            const getAppointments = {
                url: Config.baseUrl + `${Config.pathPrefix}orgs/*/appointments?patientId=${patientId}&endAfter=${currentTime}`,
                method: 'GET',
                headers: { "Authorization": idToken }
            }
            AxiosRequest(getAppointments).then((result) => {
                let appointments = result.data.appointments.filter((e) => e.status !== 'rejected' && e.category === 'teleMedicine');
                log.debug("getAppointments result:", appointments);
                resolve(appointments);
            }).catch((err) => {
                log.error("getAppointments error:", err);
                reject(err);
            })
        })
    }

    onStateUpdated(state) {
        log.debug("onStateUpdated:", state);

        function findStream(id) {
            for (let stream of state.streams.values()) {
                if (stream.participantId === id) {
                    return stream;
                }
            }
            return null;
        }

        // remove any video streams that have been removed..
        for (let id in this.videoTracks) {
            const stream = findStream(id);
            if (!stream) {
                const videoElement = this.videoTracks[id];
                log.debug(`Removing video element for ${id}:`, videoElement);
                if (videoElement && videoElement.parentNode) {
                    videoElement.parentNode.removeChild(videoElement);
                }
                delete this.videoTracks[id];
            }
        }

        // same for removing any audio streams..
        for (let id in this.audioTracks) {
            const stream = findStream(id);
            if (!stream) {
                const audioElement = this.audioTracks[id];
                log.debug(`Removing audio element for ${id}:`, audioElement);
                if (audioElement && audioElement.parentNode) {
                    audioElement.parentNode.removeChild(audioElement);
                }
                delete this.audioTracks[id];
            }
        }

        // add any new video streams to be displayed..
        state.streams.forEach((stream) => {
            if (stream.videoTrack) {
                let videoElement = this.videoTracks[stream.participantId];
                if (!videoElement) {
                    const isLocal = state.localParticipantId === stream.participantId;
                    const container = isLocal ? this.localMediaContainer.current : this.remoteMediaContainer.current;
                    if (container) {
                        videoElement = document.createElement('video');
                        videoElement.srcObject = new MediaStream([stream.videoTrack]);
                        videoElement.controls = false;
                        videoElement.autoplay = true;

                        container.appendChild(videoElement);
                        this.videoTracks[stream.participantId] = videoElement;          // save the video element so we can detach as well
                        log.debug("Added videoElement:", videoElement, stream);
                    }
                } else {
                    if (!videoElement.srcObject.getTrackById(stream.videoTrack.id)) {
                        log.debug("Updating videoElement:", videoElement, stream.videoTrack);
                        videoElement.srcObject = new MediaStream([stream.videoTrack])
                    }
                }
            }
            if (stream.audioTrack && state.localParticipantId !== stream.participantId) {
                let audioElement = this.audioTracks[stream.participantId];
                // only connect the remote audio tracks, we don't need to hear our own audio...
                if (!audioElement) {
                    const container = this.remoteMediaContainer.current;
                    if (container) {
                        audioElement = document.createElement('audio');
                        audioElement.srcObject = new MediaStream([stream.audioTrack]);
                        audioElement.controls = false;
                        audioElement.autoplay = true;
                        container.appendChild(audioElement);

                        this.audioTracks[stream.participantId] = audioElement;

                        // handle some browser that do not autoplay..
                        const playPromise = audioElement.play();
                        if (playPromise !== undefined) {
                            playPromise.then(() => {
                                log.debug('Remote audio stream playing:', stream);
                            }).catch((err) => {
                                log.error('Failed to play remote audio stream:', stream);
                            })
                        }
                        log.debug("Added audioElement:", audioElement, stream);
                    }
                } else {
                    if (!audioElement.srcObject.getTrackById(stream.audioTrack.id)) {
                        log.debug("Updating audioElement:", audioElement, stream.audioTrack);
                        audioElement.srcObject = new MediaStream([stream.audioTrack]);
                    }
                }
            }

            const remoteConnected = Object.keys(this.videoTracks).length > 1;
            this.setState({ remoteConnected, message: !remoteConnected ? <T.span text='waitingForProvider' /> : null });
        })

    }

    onRoomDisconnected() {
        const { state: { appointment } } = this;
        if ( appointment ) {
            log.metric(
                { EventName: "AppointmentDisconnect" },
                { AppointmentId: appointment.appointmentId },
                { PatientId: appointment.patientId }
            );
        }
    }

    render() {
        const { appContext } = this.props;
        const { userId, patientId } = appContext.state;
        const { redirect, getContent, dialog, apptNow, apptDone, appointmentIn, appointmentTime, 
            providerName, unconfirmedAppointments } = this.state;
        if (redirect) {
            return <Redirect to={redirect} />;
        }

        let appointmentKey = appointmentTime ? (apptNow && !apptDone) ? 'appointmentNow' : 'appointment' : 'noAppointment';
        let appointment = getContent ? <T.span text={{ key: appointmentKey, providerName, appointmentTime, appointmentIn }} /> : null;
        let icon = React.cloneElement(this.props.icon, { fontSize: 'small', style: { fill: this.props.color } });
        return <Grid id={this.props.id} container align="center" style={{ marginTop: 50 }}>
            <Grid item style={{ width: '100%', marginTop: 15, marginBottom: 15 }}>
                <Paper style={{ textAlign: 'left', marginLeft: 15, marginRight: 15, marginTop: 5, marginBottom: 5, padding: 15 }} >
                    <div style={{ marginBottom: 15, paddingBottom: 15, borderBottom: `1px solid grey` }}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item style={{ marginRight: 10 }}>
                                <Avatar style={{ width: 35, height: 35, border: `2px solid ${this.props.color}`, backgroundColor: 'white' }}>{icon}</Avatar>
                            </Grid>
                            <Grid item>
                                <span style={{ fontSize: 22, fontColor: '#666666' }}>{this.props.label}</span>
                            </Grid>
                            <Grid item style={{ marginLeft: 40 }}>
                                {appointment}
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container>
                        <Grid item style={{width: '100%'}}>
                            <TelnyxVideoChat appContext={appContext} 
                                roomName={patientId} 
                                userId={userId} 
                                onRoomDisconnected={this.onRoomDisconnected.bind(this)} 
                                disconnectRedirect='/appointments' />
                        </Grid>
                    </Grid>
                    {unconfirmedAppointments && unconfirmedAppointments.length > 0 && 
                        <div style={{ textAlign: 'center' }}>
                            <br />
                            <T.span text={{ key: 'numberUnconfirmedAppointments', number: `${unconfirmedAppointments.length}` }} />
                            <br /><br />
                            <Button variant="outlined" onClick={() => { this.setState({ redirect: '/appointments' }); }} ><AppointmentsIcon /><T.span text="viewAppointments" /></Button>
                    </div>}
                </Paper>
                {dialog}
            </Grid>
        </Grid>;
    }
}

export default VideoChatView;
