import React, { Component } from 'react';
import {
    CircularProgress,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Tooltip,
    IconButton
} from '@material-ui/core/';

import { Redirect } from 'react-router-dom';
import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import { Logger } from '@apricityhealth/web-common-lib'

import Config from '@apricityhealth/web-common-lib/Config';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';

import * as Telnyx from '@telnyx/video';

import T from 'i18n-react';

import MicIcon from '@material-ui/icons/Mic';
import MicMutedIcon from '@material-ui/icons/MicOff';
import SpeakerIcon from '@material-ui/icons/VolumeUp';
import SpeakerOffIcon from '@material-ui/icons/VolumeOff';
import VideoIcon from '@material-ui/icons/Videocam';
import VideoOffIcon from '@material-ui/icons/VideocamOff';
import DisconnectIcon from '@material-ui/icons/Stop';

function getLocalStream(state) {
    for (let stream of state.streams.values()) {
        if (stream.origin === 'local') {
            return stream;
        }
    }
    return null;
}

const log = new Logger();
const USE_REACT_NATIVE = false;             // set to true, to send message to the react-native app to connect to the video room instead
const DISABLE_TOKEN_REFRESH = false;

export class TelnyxVideoChat extends Component {
    constructor(props) {
        super(props);
        if (!props.roomName) {
            throw new Error("roomName is a required property!")
        }
        if (!props.userId) {
            throw new Error("userId is a required property!")
        }
        this.state = {
            headerText: props.headerText || '',
            connecting: false,
            connected: false,
            remoteConnected: false,
            message: null,
            disabled: false,
            disabledMessage: null,
            dialog: null,
            devices: {
                audioinput: [],
                audiooutput: [],
                videoinput: []
            },
            isSoundEnabled: true,
            isAudioEnabled: true,
            isVideoEnabled: true,
            audioInputDeviceId: localStorage.getItem("audioInputDeviceId") || null,
            audioOutputDeviceId: localStorage.getItem("audioOutputDeviceId") || null,
            videoInputDeviceId: localStorage.getItem("videoInputDeviceId") || null
        };

        this.room = null;
        this.videoTracks = {};
        this.audioTracks = {};
        this.localMediaContainer = React.createRef();
        this.remoteMediaContainer = React.createRef();
    }

    componentDidMount() {
        if (typeof this.props.appContext?.registerMessageHandler === 'function') {
            this.props.appContext.registerMessageHandler(this.onMessage.bind(this));
        }

        this.onDeviceChanged = (e) => {
            log.info("onDeviceChanged event:", e);
            this.initializeDevices();
        }

        // call when any devices change...
        navigator.mediaDevices.addEventListener('devicechange', this.onDeviceChanged);
        // connect to the room when we mount..
        this.connectToRoom();
    }

    componentWillUnmount() {
        // remove the event listener...
        navigator.mediaDevices.removeEventListener('devicechange', this.onDeviceChanged);

        if (typeof this.props.appContext?.unregisterMessageHandler === 'function') {
            this.props.appContext.unregisterMessageHandler(this.onMessage.bind(this));
        }
        this.disconnectRoom();
    }

    initializeDevices() {
        return new Promise((resolve, reject) => {
            let { audioInputDeviceId, audioOutputDeviceId, videoInputDeviceId } = this.state;
            Telnyx.getDevices().then((devices) => {
                log.info("Telnyx.getDevices:", devices);

                function fixupLabels(devices) {
                    if (Array.isArray(devices)) {
                        for (let i = 0; i < devices.length; ++i) {
                            const device = devices[i];
                            if (device.label.toLowerCase().indexOf('facing back') >= 0) {
                                device.label = "Back Facing Camera"
                            }
                            if (device.label.toLowerCase().indexOf('facing front') >= 0) {
                                device.label = "Front Facing Camera";
                            }
                            if (device.label.toLowerCase().indexOf('mic 1') >= 0) {
                                device.label = "Microphone";
                            }
                            if (device.label.toLowerCase().indexOf('speaker 1') >= 0) {
                                device.label = "Speakers";
                            }
                            if (device.label.toLowerCase().indexOf('headset') >= 0) {
                                device.label = "Headset";
                            }
                        }
                    }
                }
                fixupLabels(devices.audioinput);
                fixupLabels(devices.audiooutput);
                fixupLabels(devices.videoinput);

                if (audioInputDeviceId === null || !devices.audioinput.find((k) => k.id === audioInputDeviceId)) {
                    audioInputDeviceId = devices.audioinput.length > 0 ? devices.audioinput[0].id : null;
                }
                if (audioOutputDeviceId === null || !devices.audiooutput.find((k) => k.id === audioOutputDeviceId)) {
                    audioOutputDeviceId = devices.audiooutput.length > 0 ? devices.audiooutput[0].id : null;
                }
                if (videoInputDeviceId === null || !devices.videoinput.find((k) => k.id === videoInputDeviceId)) {
                    videoInputDeviceId = devices.videoinput.length > 0 ? devices.videoinput[0].id : null;
                }
                if (audioInputDeviceId !== null && videoInputDeviceId !== null) {
                    log.info(`audioInputDeviceId: ${audioInputDeviceId}, audioOutputDeviceId: ${audioOutputDeviceId}, videoInputDeviceId: ${videoInputDeviceId}`);
                    localStorage.setItem("audioInputDeviceId", audioInputDeviceId);
                    localStorage.setItem("audioOutputDeviceId", audioOutputDeviceId);
                    localStorage.setItem("videoInputDeviceId", videoInputDeviceId);
                    this.setState({ disbled: false, disabledMessage: null, devices, audioInputDeviceId, audioOutputDeviceId, videoInputDeviceId }, () => resolve());
                } else {
                    log.error("audio or video device unavailable!");
                    this.setState({
                        disabled: true,
                        disabledMessage: <div className='notSupported' align='center'><T.span text='videoNotSupported' /></div>
                    }, () => reject(new Error("audio/video device unavailable!")));
                }
            })
        })
    }

    onMessage(msg) {
        try {
            if (typeof msg.data === 'string') {
                log.debug("onMessage:", msg);
                let data = JSON.parse(msg.data);
                if (this[data.action] !== undefined)
                    this[data.action](data);
            }
        }
        catch (err) {
            log.warning("onMessage error:", err);
        }
    }

    onForeground(data) {
        // when the app is back in the forground, we need to reconnect to the room!
        this.connectToRoom();
    }

    onSetState(data) {
        this.setState(data.state);
    }

    onStateUpdated(state) {
        const { isSoundEnabled } = this.state;

        log.debug("onStateUpdated:", state);

        function findStream(id) {
            for (let stream of state.streams.values()) {
                if (stream.participantId === id) {
                    return stream;
                }
            }
            return null;
        }

        // remove any video streams that have been removed..
        for (let id in this.videoTracks) {
            const stream = findStream(id);
            if (!stream) {
                const videoElement = this.videoTracks[id];
                log.debug(`Removing video element for ${id}:`, videoElement);
                if (videoElement && videoElement.parentNode) {
                    videoElement.parentNode.removeChild(videoElement);
                }
                delete this.videoTracks[id];
            }
        }

        // same for removing any audio streams..
        for (let id in this.audioTracks) {
            const stream = findStream(id);
            if (!stream) {
                const audioElement = this.audioTracks[id];
                log.debug(`Removing audio element for ${id}:`, audioElement);
                if (audioElement && audioElement.parentNode) {
                    audioElement.parentNode.removeChild(audioElement);
                }
                delete this.audioTracks[id];
            }
        }

        // add any new video streams to be displayed..
        state.streams.forEach((stream) => {
            if (stream.videoTrack) {
                let videoElement = this.videoTracks[stream.participantId];
                if (!videoElement) {
                    const isLocal = state.localParticipantId === stream.participantId;
                    const container = isLocal ? this.localMediaContainer.current : this.remoteMediaContainer.current;
                    if (container) {
                        videoElement = document.createElement('video');
                        videoElement.srcObject = new MediaStream([stream.videoTrack]);
                        //videoElement.controls = false;                    // providing false, still shows the controls, so I guess we need to not provide at all to hide the controls
                        videoElement.autoplay = true;
                        videoElement.playsInline = true;

                        if ( videoElement.hasAttribute('controls')) {
                            videoElement.removeAttribute('controls');
                        }
                        container.appendChild(videoElement);
                        this.videoTracks[stream.participantId] = videoElement;          // save the video element so we can detach as well
                        log.debug("Added videoElement:", videoElement, stream);
                    } else {
                        log.warning("video container is null!");
                    }
                } else {
                    const foundTrack = videoElement.srcObject.getTrackById(stream.videoTrack.id);
                    const updateTrack = !foundTrack;
                    log.info(`videoElement found, updateTrack: ${updateTrack}:`, videoElement, foundTrack, stream.videoTrack);
                    if (updateTrack) {
                        log.debug("Updating videoElement:", videoElement, stream.videoTrack);
                        videoElement.srcObject = new MediaStream([stream.videoTrack])
                    }
                }

                if (videoElement) {
                    // handle some browser that do not autoplay..
                    const playPromise = videoElement.play();
                    if (playPromise !== undefined) {
                        playPromise.then(() => {
                            log.debug('Remote video stream playing:', stream);
                        }).catch((err) => {
                            log.error('Failed to play remote video stream:', err, stream);
                        })
                    }
                }
            }
            if (stream.audioTrack && state.localParticipantId !== stream.participantId) {
                let audioElement = this.audioTracks[stream.participantId];
                // only connect the remote audio tracks, we don't need to hear our own audio...
                if (!audioElement) {
                    const container = this.remoteMediaContainer.current;
                    if (container) {
                        audioElement = document.createElement('audio');
                        audioElement.srcObject = new MediaStream([stream.audioTrack]);
                        audioElement.controls = false;
                        audioElement.autoplay = true;
                        audioElement.muted = isSoundEnabled ? false : true;
                        container.appendChild(audioElement);

                        this.audioTracks[stream.participantId] = audioElement;
                        log.debug("Added audioElement:", audioElement, stream);
                    }
                } else {
                    const foundTrack = audioElement.srcObject.getTrackById(stream.audioTrack.id);
                    const updateTrack = !foundTrack;
                    log.info(`audioElement found, updateTrack: ${updateTrack}:`, audioElement, foundTrack, stream.audioTrack);
                    if (updateTrack) {
                        log.debug("Updating audioElement:", audioElement, stream.audioTrack);
                        audioElement.srcObject = new MediaStream([stream.audioTrack]);
                    }
                }

                if (audioElement) {
                    // handle some browser that do not autoplay..
                    const playPromise = audioElement.play();
                    if (playPromise !== undefined) {
                        playPromise.then(() => {
                            log.debug('Remote audio stream playing:', stream);
                        }).catch((err) => {
                            log.error('Failed to play remote audio stream:', err, stream);
                        })
                    }
                }
            }

            const remoteConnected = Object.keys(this.videoTracks).length > 1;
            this.setState({ remoteConnected, message: !remoteConnected ? <T.span text='waitingForRemote' /> : null });
        })

    }

    initializeLocalStream() {
        return new Promise((resolve, reject) => {
            if (this.room) {
                const state = this.room.getState();
                log.info("initializeLocalStream:", state);

                const localStream = getLocalStream(state);
                log.debug("localStream:", localStream);

                this.initializeDevices().then(() => {
                    const { audioInputDeviceId, videoInputDeviceId } = this.state;
                    return navigator.mediaDevices.getUserMedia({ audio: { deviceId: audioInputDeviceId }, video: { deviceId: videoInputDeviceId } });
                }).then((stream) => {
                    const { isAudioEnabled, isVideoEnabled } = this.state;
                    log.info("stream:", stream);

                    const audioTracks = stream.getAudioTracks();
                    const audio = audioTracks.length > 0 ? audioTracks[0] : undefined;
                    const videoTracks = stream.getVideoTracks();
                    const video = videoTracks.length > 0 ? videoTracks[0] : undefined;

                    if (audio) {
                        audio.enabled = isAudioEnabled;
                    }
                    if (video) {
                        video.enabled = isVideoEnabled;
                    }

                    if (localStream) {
                        return this.room.updateStream(localStream.key, { audio, video });
                    } else {
                        return this.room.addStream('self', { audio, video });
                    }
                }).then(() => {
                    if (window.ReactNativeWebView) {
                        // HACK: Call initialzieDevices again 1 second after we initialize the local stream
                        // if refused to get all the devices the very first time.
                        setTimeout(() => {
                            log.info("HACK: Calling intializeDevices() one more time!");
                            this.initializeDevices();
                        }, 1000);
                    }
                    resolve();
                }).catch((err) => {
                    log.error("initializeLocalStream error:", err);
                    reject(err);
                })
            } else {
                reject(new Error("no room initialized!"))
            }
        })
    }

    connectToRoom() {
        if (!this.connectingToRoom) {
            this.connectingToRoom = true;       // prevent accidently requesting a connection twice 

            let { appContext, roomName, userId } = this.props;
            let url = Config.baseUrl + `${Config.pathPrefix}messaging/rooms/${roomName}/token?maxParticipants=2`;
            let request = {
                url: url,
                method: 'GET',
                headers: { "Authorization": appContext.state.idToken },
            }

            this.setState({
                connected: false,
                connecting: true,
                message: <T.span text='connectingToRoom' />,
                progress: <CircularProgress size={30} />,
                error: null
            });
            log.debug("get room token Request:", request);
            AxiosRequest(request).then((response) => {
                log.info("getRoomToken result:", response.data);
                const { token: clientToken, refresh_token } = response.data.token;
                log.debug('clientToken:', clientToken );
                log.debug('refresh_token:', refresh_token );
                const { id: roomId } = response.data.room;
                const isReactNative = window.ReactNativeWebView && USE_REACT_NATIVE;

                if (!isReactNative) {
                    Telnyx.initialize({
                        roomId,
                        clientToken,
                        context: JSON.stringify({ userId }),
                        logLevel: 'INFO',
                        enableMessages: true
                    }).then((room) => {
                        log.info("Connecting to room:", room);
                        this.room = room;

                        if ( this.refreshTimer ) {
                            clearInterval( this.refreshTimer );
                            this.refreshTimer = null;
                        }
                        // start a timer to keep our client token refreshed..
                        this.refreshTimer = !DISABLE_TOKEN_REFRESH && setInterval( () => {
                            log.debug("Refreshing client token!");
                            let request = {
                                url: `https://api.telnyx.com/v2/rooms/${roomId}/actions/refresh_client_token`,
                                method: 'POST',
                                data: {
                                    token_ttl_secs: 600,
                                    refresh_token
                                }
                            };
                            log.debug("refresh clientToken request:", request );
                            AxiosRequest(request).then((response) => {
                                log.debug("refesh clientToken response:", response.data );
                                const { token: clientToken } = response.data.data;
                                room.updateClientToken( clientToken );
                            })
                        }, 300 * 1000 );

                        room.on('connected', (state) => {
                            log.info("Room connected:", state);
                            state.streams.forEach((stream) => {
                                // for each stream, which it's not us, subscribe to that stream..
                                if (stream.participantId !== room.getLocalParticipant().id) {
                                    room.addSubscription(stream.participantId, stream.key, {
                                        audio: true,
                                        video: true
                                    })
                                }
                            })
                            this.setState({ error: null, progress: null, connecting: false, connected: true });
                            this.initializeLocalStream().catch((err) => {
                                log.error("initializeLocalStream error:", err);
                                this.setState({ error: getErrorMessage(err) });
                            })
                        })
                        room.on('stream_published', (participantId, streamKey, state) => {
                            log.info("stream_published:", participantId, streamKey, state);

                            if (participantId !== room.getLocalParticipant().id) {
                                room.addSubscription(participantId, streamKey, {
                                    audio: true,
                                    video: true
                                })
                            }
                        })
                        room.on("state_changed", (state) => {
                            log.debug("state_changed:", state);
                            this.onStateUpdated(state);
                        })
                        room.on('disconnected', (state) => {
                            log.info("disconnected:", state );
                            // if this was not an intended disconnect, then call connectToRoom() to connect back into the room
                            if (this.room) {
                                log.info("Reconnecting to the room!");
                                this.connectToRoom();
                            }
                        })
                        this.setState({ message: <T.span text="waitingForRemote" /> });

                        return room.connect();
                    }).catch((err) => {
                        log.error("connectToRoom error:", err);
                        this.setState({ progress: null, error: getErrorMessage(err) })
                    })
                } else if (isReactNative) {
                    appContext.postMessage({ action: "onConnectToRoom", clientToken, roomId });
                    this.setState({ progress: null });
                }
                this.connectingToRoom = false;
            }).catch((error) => {
                log.error("connectToRoom error:", error);
                this.setState({ message: null, progress: null, error: getErrorMessage(error) });
                this.connectingToRoom = false;
            });
        }
    }

    disconnectRoom() {
        if (this.room) {
            log.debug(`Disconnecting:`, this.room);

            if ( this.refreshTimer ) {
                clearInterval( this.refreshTimer );
                this.refreshTimer = null;
            }

            for (let id in this.videoTracks) {
                const videoElement = this.videoTracks[id];
                if (videoElement && videoElement.parentNode) {
                    log.debug("removing videoElement:", videoElement, id);
                    videoElement.parentNode.removeChild(videoElement);
                }
                delete this.videoTracks[id];
            }

            for (let id in this.audioTracks) {
                const audioElement = this.audioTracks[id];
                if (audioElement && audioElement.parentNode) {
                    log.debug("removing audioElement:", audioElement, id);
                    audioElement.parentNode.removeChild(audioElement);
                }
                delete this.audioTracks[id];
            }

            const state = this.room.getState();
            state.streams.forEach((stream) => {
                if (stream.origin === 'local') {
                    log.info("Removing local stream:", stream);
                    if (stream.videoTrack) {
                        stream.videoTrack.stop();
                    }
                    if (stream.audioTrack) {
                        stream.audioTrack.stop();
                    }
                    this.room.removeStream(stream.key);
                }
            });
            this.room.disconnect();
            this.room = null;

            this.onRoomDisconnected();
        }
    }

    toggleAudioEnabled() {
        let { isAudioEnabled } = this.state;
        isAudioEnabled = !isAudioEnabled;
        if (this.room) {
            const localStream = getLocalStream(this.room.getState());
            if (localStream && localStream.audioTrack) {
                localStream.audioTrack.enabled = isAudioEnabled;
            }
        }
        this.setState({ isAudioEnabled });
    }

    toggleVideoEnabled() {
        let { isVideoEnabled } = this.state;
        isVideoEnabled = !isVideoEnabled;
        if (this.room) {
            const localStream = getLocalStream(this.room.getState());
            if (localStream && localStream.videoTrack) {
                localStream.videoTrack.enabled = isVideoEnabled;
            }
        }
        this.setState({ isVideoEnabled });
    }

    toggleSoundEnabled() {
        let { isSoundEnabled } = this.state;
        isSoundEnabled = !isSoundEnabled;
        for (let k in this.audioTracks) {
            const audioElement = this.audioTracks[k];
            audioElement.muted = isSoundEnabled ? false : true;
        }
        this.setState({ isSoundEnabled });
    }

    onRoomDisconnected() {
        if (typeof this.props.onRoomDisconnected === 'function') {
            this.props.onRoomDisconnected();
        }

        this.setState({
            room: null,
            connected: false,
            connecting: false,
            progress: null,
            message: null
        });
    }

    render() {
        const { error, redirect, dialog, isAudioEnabled, isSoundEnabled, isVideoEnabled,
            message, remoteConnected, disabledMessage, connected,
            devices, audioInputDeviceId, videoInputDeviceId } = this.state;

        if (redirect) {
            return <Redirect to={redirect} />;
        }

        if (disabledMessage) {
            return <div>{disabledMessage}</div>
        }

        let controls = connected ? <div>
            <Tooltip title={isAudioEnabled ? <T.span text='muteMicrophone' /> : <T.span text='unmuteMicrophone' />} >
                <IconButton onClick={this.toggleAudioEnabled.bind(this)}>{isAudioEnabled ? <MicIcon /> : <MicMutedIcon />}</IconButton>
            </Tooltip>
            <Tooltip title={isVideoEnabled ? <T.span text='cameraOff' /> : <T.span text='cameraOn' />} >
                <IconButton onClick={this.toggleVideoEnabled.bind(this)}>{isVideoEnabled ? <VideoIcon /> : <VideoOffIcon />}</IconButton>
            </Tooltip>
            <Tooltip title={isSoundEnabled ? <T.span text='muteSound' /> : <T.span text='unmuteSound' />} >
                <IconButton onClick={this.toggleSoundEnabled.bind(this)}>{isSoundEnabled ? <SpeakerIcon /> : <SpeakerOffIcon />}</IconButton>
            </Tooltip>
            {this.props.disconnectRedirect && <Tooltip title={<T.span text='disconnectVideoChat' />} >
                <IconButton onClick={() => this.setState({ redirect: this.props.disconnectRedirect })}><DisconnectIcon /></IconButton>
            </Tooltip>}
            <br />
            {devices.audioinput.length > 1 && <FormControl style={{ margin: 15, width: 300 }}>
                <InputLabel>Audio Input</InputLabel>
                <Select MenuProps={{ disablePortal: true }} value={audioInputDeviceId} onChange={(e) => {
                    this.setState({ audioInputDeviceId: e.target.value }, this.initializeLocalStream.bind(this))
                }}>
                    {devices.audioinput.map((device, i) => {
                        return <MenuItem value={device.id} key={i}>{device.label}</MenuItem>
                    })}
                </Select>
            </FormControl>}
            {devices.videoinput.length > 1 && <FormControl style={{ margin: 15, width: 300 }} >
                <InputLabel>Video Input</InputLabel>
                <Select MenuProps={{ disablePortal: true }} value={videoInputDeviceId} onChange={(e) => {
                    this.setState({ videoInputDeviceId: e.target.value }, this.initializeLocalStream.bind(this))
                }}>
                    {devices.videoinput.map((device, i) => {
                        return <MenuItem value={device.id} key={i}>{device.label}</MenuItem>
                    })}
                </Select>
            </FormControl>}
            <br />
        </div> : null;

        return <div>
            <div className='video-container' align='center'>
                <div className='remoteVideo' ref={this.remoteMediaContainer} />
                <div className={remoteConnected ? 'localVideo' : 'remoteVideo'} ref={this.localMediaContainer} />
            </div>
            {controls}
            <div style={{ width: '100%', textAlign: 'center' }}>
                {message}
            </div>
            <div style={{ width: '100%', color: 'red' }}>
                {error}
            </div>
            {dialog}
        </div>;
    }
}

export default TelnyxVideoChat;

