import React from 'react';
import { 
    Grid,
    Paper,
    Dialog,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

import T from 'i18n-react';
import Moment from 'moment';

import _ from 'lodash';

import { EmployeeStatusView } from './EmployeeStatusView';
import { HomeStatusView } from './HomeStatusView';
import { ActionButton } from '../components/ActionButton';
import { ToggleButton } from '../components/ToggleButton';
import { SymptomsDialog } from '../dialogs/SymptomsDialog';
import { TermsDialog } from '../dialogs/TermsDialog';
import { Logger } from '@apricityhealth/web-common-lib';

import CheckInIcon from '@material-ui/icons/VerifiedUser';
import SurveyIcon from '@material-ui/icons/Poll';
import VideoIcon from '@material-ui/icons/Videocam';
import MessagesIcon from '@material-ui/icons/Email';
import ProfileIcon from '@material-ui/icons/Person';
import ReportIcon from '@material-ui/icons/Description';
import AppointmentsIcon from '@material-ui/icons/Today';
import CallIcon from '@material-ui/icons/Call';
import FAQIcon from '@material-ui/icons/Assignment';
import FeedbackIcon from '@material-ui/icons/Feedback';
import SettingsIcon from '@material-ui/icons/Settings'
import EducationIcon from '@material-ui/icons/OndemandVideo';
import ClearanceIcon from '@material-ui/icons/ClearAll';
import OnsiteIcon from '@material-ui/icons/Business';
import SunIcon from '@material-ui/icons/WbSunny';
import PinDropRoundedIcon from '@material-ui/icons/PinDropRounded';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import SymptomsIcon from '@material-ui/icons/ListAlt';
import MedicationsIcon from '@material-ui/icons/LocalPharmacy';
import AddBoxIcon from '@material-ui/icons/AddBox';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import UserGuideIcon from '@material-ui/icons/ImportContacts';
import VaccinationIcon from '@material-ui/icons/HowToReg';

import videoChatReadySound from '../sounds/videoChatReady.wav';
import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';
import { isArrayValid } from '@apricityhealth/web-common-lib/utils/Services';
import MedicationsDialog from '../dialogs/MedicationsDialog';

const log = new Logger();

function setIntervalImmediate( func, interval ) {
    func();
    return setInterval( func, interval);
}

export function isVisible(appContext, config, orgConfig, key) {
    let value = config ? config[key] : null;
    if (! value ) {
        return false;
    }
    if ( value.condition ) {
        try {
            // eslint-disable-next-line
            if ( !eval( value.condition )) {
                //log.debug("condition false:", value, appContext.state );
                return false;
            }
        } catch( err ) {
            log.error("isVisible caught error:", err );
        }
    }
    if ( value.orgConfig !== undefined ) {
        let orgValue = orgConfig[value.orgConfig.key] || value.orgConfig.default;
        //log.debug(`orgValue: ${orgValue}, value: ${value.orgConfig.value}`, orgConfig );
        if ( orgValue !== value.orgConfig.value ) {
            //log.debug("disabled by org:", value );
            return false;
        }
    }
    if ( value.visible !== undefined ) {
        return value.visible;
    } else {
        return value !== false;
    }
}

export function isDisabled(config,key) {
    return config && config[key] && config[key].disabled !== undefined ? config[key].disabled : false; 
}

export function getSettings(config,key, def = {}) {
    //log.debug("getSettings:", config, key, def );
    return _.get(config,key,def);
}

class GreetingsView extends React.Component {

    // Moment has limited locale aware formats
    // using what they provide to generate DayOfWk, Month Day, Year (we remove time) 
    localWelcomeDate(language) {
        const timePart = Moment().format('LT');
        const fullLocalDateTime = Moment().format('LLLL');
        return fullLocalDateTime.replace(timePart, '');
    }

    render() {
        const { appContext, settings } = this.props;
        const { firstName } = appContext.state.patient;
        const { language } = appContext.state;

        let localHour = Moment().local().hour();
        let textId = null;
        for (let i = 0; i < settings.salutations.length; ++i) {
            let salutation = settings.salutations[i];
            if (localHour >= salutation.startHour && localHour <= salutation.endHour) {
                textId = salutation.textId; 
                break;
            }
        }

        let date = this.localWelcomeDate(language);

        if (textId && firstName) {
            return <Grid item style={{width: '100%', marginTop:15, marginBottom:10}}>
                <T.span style={{ fontSize:20, fontWeight:'bold'}} text={{key: textId, firstName}} />
                <br></br>
                <span style={{ color: '#888888', fontSize: 14 }}>{date}</span>
                </Grid>;
        }
        return null;
    }
};

class CompanyName extends React.Component {
    render() {
        const { appContext } = this.props;
        const { org } = appContext.state;
        if (org && org.name) {
            return <Grid item style={{width: '100%', padding: 10}}><div style={{ fontSize:14, fontStyle: 'italic', color: '#aaaaaa' }}><T.span text={{key: 'companyName', name: org.name}} /></div></Grid>;
        }
        return null;
    }
}

class TextView extends React.Component {
    render() {
        const { settings, appContext } = this.props;
        if (! settings.textId ) throw new Error("textId is a required parameter");

        function hookDiv(ref) {
            if ( ref ) {
                ref.addEventListener('click',(e) => {
                    if ( appContext.onURL && e.target.tagName === 'A' ) {
                        appContext.onURL({ href: e.target.href} );
                        e.preventDefault();
                    }
                });
            }
        }

        let text = T.texts[settings.textId];
        let icon = React.cloneElement( this.props.icon, {fontSize: 'small', style: {fill: this.props.color} } );
        if ( settings.enableHTML ) {
            return <Grid item style={{width: '100%', marginTop: 15, marginBottom: 15}}>
                    <Paper style={{marginLeft: 15, marginRight: 15, marginTop: 5, marginBottom: 5, padding: 15}} >
                        <div style={styles.div}>
                            <div style={{ marginBottom: 15, paddingBottom: 15, borderBottom: `1px solid grey` }}>
                            <Grid container direction="row" alignItems="center">
                                <Grid item style={{ marginRight: 10 }}>
                                    <Avatar style={{ width: 35, height: 35, border: `2px solid ${this.props.color}`, backgroundColor: 'white' }}>{icon}</Avatar>
                                </Grid>
                                <Grid item >
                                    <span style={{ fontSize: 22, fontColor: '#666666' }}>{this.props.label}</span>
                                </Grid>
                            </Grid>
                            </div>
                            <div ref={hookDiv} style={styles.div} dangerouslySetInnerHTML={{ __html: text }} />
                        </div>
                    </Paper>
                </Grid>;
        }   
        else {
            return <Grid item style={{width: '100%'}}>
                <Paper style={styles.paper}><div ref={hookDiv} style={styles.div}>{text}</div></Paper>
            </Grid>;
        }
    }
}

export class DashboardView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dialog: null,
            room: null
        }
    }

    componentDidMount() {
        this.startVideoChatTimer();
        this.startMessageTimer();
        this.startAppointmentTimer();
    }

    componentWillUnmount() {
        clearInterval(this.videoTimer);
        clearInterval(this.messageTimer);
        clearInterval(this.appointmentTimer);
    }

    playNotification() {
        try {
            const readyAudio = new Audio(videoChatReadySound);
            readyAudio.play();
        }
        catch (err) {
            log.debug("Failed to play sound:", err);
        }
    }

    startVideoChatTimer() {
        const { appContext } = this.props;
        this.videoTimer = setIntervalImmediate(() => {
            const { config, org } = appContext.state;
            const orgConfig = _.get(org,"config") || {};
            if (isVisible(appContext, config.dashboard, orgConfig, "videoChat") && appContext.state.patientId) {
                const { patientId, idToken } = appContext.state;
                let listRooms = {
                    url: Config.baseUrl + `${Config.pathPrefix}messaging/listRooms?roomName=${patientId}`,
                    method: 'GET',
                    headers: { "Authorization": idToken },
                }

                //log.debug("listRooms request:", listRooms);
                AxiosRequest(listRooms).then((response) => {
                    let { room } = this.state;

                    //log.debug("listRooms response:", response);
                    let rooms = response.data;
                    if (rooms.length + this.state.apptCount > 0) {
                        if (!room) {
                            this.playNotification();
                        }
                        this.setState({ room: rooms[0] });
                        appContext.setState({ roomApptCount: rooms.length }, appContext.refreshMenu);
                    }
                    else if (room) {
                        // clear old room from the state
                        this.setState({ room: null });
                        appContext.setState({ roomApptCount: 0 }, appContext.refreshMenu);
                    }
                }).catch((err) => {
                    log.error("listRooms error:", err);
                    this.setState({ room: null });
                    appContext.setState({ roomApptCount: 0 }, appContext.refreshMenu);
                });
            }
        }, 15000);
    }

    startMessageTimer() {
        const { appContext } = this.props;
        
        this.messageTimer = setIntervalImmediate(() => {
            if (appContext.state.patientId) {
                const { patientId, idToken } = appContext.state;
                let getPatientMessages = {
                    url: Config.baseUrl + `${Config.pathPrefix}messaging/message/patient/${patientId}?getUnread=true&markRead=false`,
                    method: 'GET',
                    headers: { "Authorization": idToken },
                }
        
                //log.debug("getPatientMessages request:", getPatientMessages);
                AxiosRequest(getPatientMessages).then((response) => {
                    const messages = response.data.messages;
                    //log.debug("getPatientMessages result:", messages );
                    if (messages.length > 0 ) {
                        const { messageCount } = appContext.state;
                        if ( messageCount === 0 || messageCount === undefined ) {
                            this.playNotification();
                        }
                        appContext.setState({messageCount: messages.length}, appContext.refreshMenu );       // save count into app state as well
                    }
                    else {
                        appContext.setState({messageCount: 0}, appContext.refreshMenu);
                    }
                }).catch((err) => {
                    log.error("getPatientMessages error:", err);
                    appContext.setState({ messageCount: 0 }, appContext.refreshMenu);
                });
            }
        }, 30000);
    }

    startAppointmentTimer() {
        const { appContext } = this.props;
        
        this.appointmentTimer = setIntervalImmediate(() => {
            //const { config, org } = appContext.state;
            //const orgConfig = _.get(org,"config") || {};
            if (appContext.state.patientId) {
                const { patientId, idToken } = appContext.state;

                let currentTime = Moment().toISOString();
                const getAppointments = {
                    url: Config.baseUrl + `${Config.pathPrefix}orgs/*/appointments?patientId=${patientId}&endAfter=${currentTime}`,
                    method: 'GET',
                    headers: { "Authorization": idToken }
                }
                log.debug("getAppointments:", getAppointments);
                AxiosRequest(getAppointments).then((result) => {
                    log.debug("getAppointments result:", result.data )
                    let appointments = result.data.appointments.filter((e) => e.status !== 'rejected');
                    log.debug("appointments:", appointments );
                    if ( appointments.length > 0 ) {
                        const { apptCount } = appContext.state;
                        if ( apptCount === 0 || apptCount === undefined ) 
                            this.playNotification();
                        appContext.setState({apptCount: appointments.length}, appContext.refreshMenu);
                    }
                    else {
                        appContext.setState({apptCount: 0}, appContext.refreshMenu)
                    }
                }).catch((err) => {
                    log.error("getAppointments error:", err);
                    this.setState({ apptCount: 0 }, appContext.refreshMenu);
                })
            } else {
                log.debug("appointments disabled!")
            }
        }, 45000);
    }

    onCloseDialog() {
        this.setState({dialog: null})
    }

    onDisplayDialog( dialog ) {
        const { appContext } = this.props;
        
        let text = T.texts[dialog.textId];
        if ( dialog.enableHTML ) {
            function hookDiv(ref) {
                if ( ref ) {
                    ref.addEventListener('click',(e) => {
                        if ( appContext.onURL && e.target.tagName === 'A' ) {
                            appContext.onURL({ href: e.target.href} );
                            e.preventDefault();
                        }
                    });
                }
            }
    
            let dialog = <Dialog open={true}>
                <DialogContent><div ref={hookDiv} style={styles.div} dangerouslySetInnerHTML={{ __html: text }} /></DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.onCloseDialog.bind(this)}><T.span text='ok' /></Button>
                </DialogActions>
            </Dialog>;
    
            this.setState({ dialog });
        }   
        else {
            let dialog = <Dialog open={true}>
                <DialogContent>{text}</DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.onCloseDialog.bind(this)}><T.span text='ok' /></Button>
                </DialogActions>
            </Dialog>;
    
            this.setState({ dialog });
        }
    }

    onViewTerms() {
        const { appContext } = this.props;
        const { patient, config, language } = appContext.state;
        let planIds = appContext.getPlanIds(patient);
        let country = appContext.getCountry(patient);
        let dialog = planIds.length > 0 ? <TermsDialog appContext={appContext} planIds={planIds}
            termIds={config.termIds} language={language} country={country} readOnly={true}
            onClose={this.onCloseDialog.bind(this)} /> : null;

        this.setState({ dialog });
    }

    render() {
        const self = this;
        const { appContext, settings = {} } = this.props;
        const { providerPhones, orgPhones, org, language, surveys, trials, patient, planActive, roomApptCount, messageCount, apptCount } = appContext.state;
        const { dialog } = this.state;
        const orgConfig = _.get(org,"config") || {};
        const newTrials = (trials || []).filter((e) => e.new);

        const COMPONENTS = {
            "greetings": <GreetingsView key='greetings' appContext={appContext} settings={getSettings(settings,'greetings')} />,

            "conversation": <ActionButton id="conversation" key='conversation' metric={'checkIn'} disabled={isDisabled(settings,'conversation') || !planActive || (patient && patient.inactive === true)} 
                to='/conversation'
                icon={<CheckInIcon />}  color='#51cc42' 
                label={<T.span text="conversation" />} label2={<T.span text="conversation-desc" />} />,

            "activity": <ActionButton id="activity" key='activity' metric={'activity'} disabled={isDisabled(settings,'activity')} 
                to='/activity'
                icon={<NetworkCheckIcon />}  color='#e6503e' 
                label={<T.span text="activity" />} label2={<T.span text="activity-desc" />} />,

            "test": <ActionButton id="test" key='test' metric={'testResults'} disabled={isDisabled(settings,'test')} 
                to='/test'
                icon={<AddBoxIcon />}  color='#d33434' 
                label={<T.span text="test" />} label2={<T.span text="test-desc" />} />,

            "clearance": <ActionButton id="clearance" key='clearance' metric={'clearance'} disabled={isDisabled(settings,'clearance')} 
                to='/clearance' 
                icon={<ClearanceIcon />}  color='#0a4089'
                label={<T.span text="clearance" />} label2={<T.span text="clearance-desc" />} />,

            "report": <ActionButton id="report" key='report' metric='eReport' disabled={isDisabled(settings,'report')} 
                to='/report' 
                icon={<ReportIcon />} color='#ff9604' 
                label={<T.span text="report" />} label2={<T.span text="report-desc" />} />,

            "profile": <ActionButton id="profile" key='profile' disabled={isDisabled(settings,'profile')} 
                to='/profile' 
                icon={<ProfileIcon />} color='#0076bb' 
                label={<T.span text="profile" />} label2={<T.span text="profile-desc" />} />,

            "messages": <ActionButton id="messages" key='messages' disabled={isDisabled(settings,'messages')} 
                to='/messages' badge={messageCount > 0 ? `${messageCount}` : null} 
                icon={<MessagesIcon/>} color='#02365c' 
                label={<T.span text="messages" />} label2={<T.span text="messages-desc" />} />,

            "surveys": <ActionButton id="surveys" key='surveys' disabled={isDisabled(settings,'surveys')} 
                to='/surveys' badge={surveys.length > 0 ? `${surveys.length}` : null} 
                icon={<SurveyIcon/>} color='#95daff' 
                label={<T.span text="surveys" />} label2={<T.span text="surveys-desc" />} />,

            "trials": <ActionButton id="trials" key='trials' disabled={isDisabled(settings,'trials')} 
                to='/trials' badge={newTrials.length > 0 ? `${newTrials.length}` : null} 
                icon={<SurveyIcon/>} color='#95daff' 
                label={<T.span text="trials" />} label2={<T.span text="trials-desc" />} />,

            "appointments": <ActionButton id="appointments" key='appointments' disabled={isDisabled(settings,'appointments')} 
                to='/appointments' badge={apptCount > 0 ? `${apptCount}` : null} 
                icon={<AppointmentsIcon />} color='#0076bb' 
                label={<T.span text="appointments" />} label2={<T.span text="appointments-desc" />} />,

            "videoChat": <ActionButton id="videoChat"key='videoChat' disabled={isDisabled(settings,'videoChat')} 
                to='/video' badge={roomApptCount + apptCount > 0  ? `${roomApptCount + apptCount}` : null}
                icon={<VideoIcon />} color='#402361' 
                label={<T.span text="videoConf" />} label2={<T.span text="videoConf-desc" />} />,

            "education": <ActionButton id="education" key='education' disabled={isDisabled(settings,'education')} 
                to='/education' 
                icon={<EducationIcon />} color='#d61818' 
                label={<T.span text="education" />} label2={<T.span text="education-desc" />} />,

            "callDr": <ActionButton id="callDr" key='callDr' disabled={isDisabled(settings,'callDr') || !isArrayValid(providerPhones)} 
                to={window.location.pathname} onClick={appContext.onCall.bind(appContext, providerPhones)} 
                icon={<CallIcon />} color='#05bffc' 
                label={<T.span text="callDr" />} label2={<T.span text="callDr-desc" />} />,

            "callOrg": <ActionButton id="callOrg" key='callOrg' disabled={isDisabled(settings,'callOrg') || !isArrayValid(orgPhones)} 
                to={window.location.pathname} onClick={appContext.onCall.bind(appContext, orgPhones)} 
                icon={<CallIcon />} color='#05bffc' 
                label={<T.span text="callOrg" />} label2={<T.span text="callOrg-desc" />} />,
            "vaccinations": <ActionButton id="vaccinations" metric='vaccinations' key='vaccinations' disabled={isDisabled(settings,'vaccinations')}
                    to='/vaccinations'
                    icon={<VaccinationIcon />} color='#0076bb'
                    label={<T.span text='vaccinations' />} label2={<T.span text="vaccination-desc" />} />,
            "information": <ActionButton id="information" metric='information' key='faq' disabled={isDisabled(settings,'information')} 
                to='/faq' 
                icon={<FAQIcon />} color='#0076bb' 
                label={<T.span text="faq" />} label2={<T.span text="faq-desc" />} />,

            "feedback": <ActionButton id="feedback" key='feedback' metric='feedback' disabled={isDisabled(settings,'feedback')} 
                to='/feedback' 
                icon={<FeedbackIcon />} color='#60D2EE' 
                label={<T.span text="feedback" />} label2={<T.span text="feedback-desc" />} />,

            "settings": <ActionButton id="settings" key='settings' metric='settings' disabled={isDisabled(settings,'settings')} 
                to='/settings' 
                icon={<SettingsIcon />} color='#026991' 
                label={<T.span text="settings" />} label2={<T.span text="settings-desc" />} />,

            "emlife": <ActionButton id="emlife" key='emlife' disabled={isDisabled(settings,'emlife')}
                to={window.location.pathname} onClick={appContext.onURL.bind(appContext, getSettings(settings,'emlife'))}
                icon={<LibraryBooksIcon />} color='#3ac9bc' 
                label={<T.span text={settings.emlife ? settings.emlife.title : 'emLife'} />}
                label2={<T.span text="emLife-desc" />} />,

            "userGuide": <ActionButton id="userGuide" key='userGuide' disabled={isDisabled(settings,'userGuide')}
                to={window.location.pathname} onClick={appContext.onOpenFile.bind(appContext, getSettings(settings,`userGuide.${language}`, null) || getSettings(settings,'userGuide.en-us'))}
                icon={<UserGuideIcon />} color='#664ea6' 
                label={<T.span text="userGuide" />} label2={<T.span text="userGuide-desc" />} />,

            "hotSpots": <ActionButton id="hotSpots" key='hotSpots' disabled={isDisabled(settings,'hotSpots')}
                to='/hotspots' 
                icon={<PinDropRoundedIcon />} color='#d61818' 
                label={<T.span text={settings.hotSpots ? settings.hotSpots.title : 'hotSpots'} />}
                label2={<T.span text="hotSpots-desc" />} />,

            "employeeStatus": <Grid key='employeeStatusGrid' item xs={12} container justifyContent={'space-evenly'} >
                <EmployeeStatusView key='employeeStatus' appContext={appContext} settings={getSettings(settings,'employeeStatus')} />
                </Grid>,

            "homeStatus": <Grid key='homeStatusGrid' item xs={12} container justifyContent={'space-evenly'} >
                <HomeStatusView key='homeStatus' appContext={appContext} settings={getSettings(settings,'homeStatus')} />
                </Grid>,

            "companyName": <CompanyName key='companyName' appContext={appContext} />,

            "onsite": <ToggleButton id="onsite" key='onsite' appContext={appContext} dataId='onSite' to='/dashboard'
                onConfirm={<T.span text='onSiteConfirm' />} offConfirm={<T.span text='offSiteConfirm' />}
                onIcon={<OnsiteIcon />} offIcon={<OnsiteIcon />} color='#f96427' 
                onLabel={<T.span text='onSite' />} offLabel={<T.span text='onSiteSelected' />}
                onLabel2={<T.span text='onSite-desc' />} offLabel2={<T.span text='onSiteSelected-desc' />} />,

            "symptoms": <ActionButton id="symptoms" key='symptoms' metric='symptoms' disabled={isDisabled(settings,'symptoms')} 
                to='/dashboard' onClick={() => {self.setState({dialog: <SymptomsDialog
                    appContext={appContext} onClose={self.onCloseDialog.bind(self)} 
                    icon={<SymptomsIcon />} color='#f96427' />});
                }} 
                icon={<SymptomsIcon />} color='#f96427'
                label={<T.span text='symptoms' />} label2={<T.span text="symptoms-desc" />} />,

            "medications": <ActionButton id="medications" key='medications' disabled={isDisabled(settings,'medications')} 
                to='/dashboard' onClick={() => {self.setState({dialog: <MedicationsDialog
                    appContext={appContext} onClose={self.onCloseDialog.bind(self)} 
                    icon={<MedicationsIcon />} color='#853cc6' />});
                }} 
                icon={<MedicationsIcon />} color='#853cc6'
                label={<T.span text='medications' />} label2={<T.span text="medications-desc" />} />,

            "resources": <ActionButton id="resources" key='resources' disabled={isDisabled(settings,'resources')}
                to='/resources' 
                icon={<SunIcon />} color='#ff9604'
                label={<T.span text='resources' />} label2={<T.span text="resources-desc" />} />,

            "resourcesIRAE": <ActionButton id="resourcesIRAE" key='resourcesIRAE' disabled={isDisabled(settings,'resourcesIRAE')}
                    to='/resources' 
                    icon={<SunIcon />} color='#ff9604'
                    label={<T.span text='resources' />} label2={<T.span text="resources-desc-irae" />} />,

            "div": <TextView key='div' appContext={appContext} settings={getSettings(settings,'div')}
                icon={this.props.icon} color={this.props.color}
                label={this.props.label} />
        };

        const viewTerms = <Button id="viewAcceptedTerms" style={styles.button} color="primary"
            onClick={this.onViewTerms.bind(this)}><T.span text='viewAcceptedTerms' /></Button>;

        let components = [];
        for(let k in settings) {
            if ( isVisible(appContext, settings, orgConfig, k)) components.push( COMPONENTS[k] );
        }
        return <Grid id={this.props.id} container justifyContent={'space-evenly'} style={{marginTop:50}}>
            {components}
            {dialog}
            <div style={styles.footer}>
                {viewTerms}
            </div>
        </Grid>;
    }
}

const styles = {
    div: {
        margin: 5,
        textAlign: 'left'
    },
    button: {
        margin: 5
    },
    footer: {
        position: 'relative',
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        paddingLeft: 10,
        paddingBottom: 10,
        textAlign: 'center'
    }
};
